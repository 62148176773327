import * as types from '../types/new';

export const fetchAllNews = () => ({
  type: types.ALL_NEWS_FETCHED,
  payload: {},
});

export const fetchAllNewsConfirm = ({
  news,
}) => ({
  type: types.ALL_NEWS_FETCHED_SUCCEEDED,
  payload: {
    news,
  },
});

export const fetchAllNewsDecline = ({
  message,
}) => ({
  type: types.ALL_NEWS_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const createNew = ({
  path_to_picture,
  name,
  link_to_news,
  franchise,
  date,
  time
}) => ({
  type: types.NEW_CREATED,
  payload: {
    path_to_picture,
    name,
    link_to_news,
    franchise,
    date,
    time
  },
});

export const createNewConfirm = () => ({
  type: types.NEW_CREATED_SUCCEEDED,
  payload: {},
});

export const createNewDecline = ({
  message,
}) => ({
  type: types.NEW_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteNew = ({
  id,
}) => ({
  type: types.NEW_DELETED,
  payload: {
    id,
  },
});

export const deleteNewConfirm = ({
  data,
}) => ({
  type: types.NEW_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteNewDecline = ({
  message,
}) => ({
  type: types.NEW_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateNew = ({
  id,
  path_to_picture,
  name,
  link_to_news,
  franchise,
}) => ({
  type: types.NEW_UPDATED,
  payload: {
    id,
    path_to_picture,
    name,
    link_to_news,
    franchise,
  },
});

export const updateNewConfirm = ({
  data,
}) => ({
  type: types.NEW_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateNewDecline = ({
  message,
}) => ({
  type: types.NEW_UPDATED_FAILED,
  payload: {
    message,
  },
});
