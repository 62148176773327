import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field  } from 'redux-form';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import * as actions from "../../../actions/modal";
import * as selectors from "../../../reducers";
import FormInput from '../FormInput';
import FormFile from '../FormFile';
import FormSelect from '../FormSelect';
import FormDate from '../FormDate';
import FormTime from '../FormTime';


class ModalCore extends Component {
  componentDidUpdate() {
    const {
      hideModal,
      type,
    } = this.props;
    if ( type === "success") {
      setTimeout(() => hideModal(), 2000);
    }
  }

  onSubmit(values) {
    const {
      updateAction,
      hideModal,

      row: {
        id,
      }
    } = this.props;
    if (values) {
      updateAction({
        id,
        ...values
      })
    }
    hideModal();
  }

  render() {
    const {
      message,
      type,
      hideModal,
      handleSubmit,
      row: {
        data,
      },
      onUmount
    } = this.props;
    return (
      <>
      {
        type === "loading" && (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            warning
            onConfirm={() => hideModal()}
          >
            {message}
          </ReactBSAlert>
        )
      }
      {
        type === "error" && (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            error
            onConfirm={() => hideModal()}
          >
            {message}
          </ReactBSAlert>
        )
      }
      {
        type === "success" && (
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Good job!"
            confirmBtnBsStyle="success"
            btnSize=""
            showConfirm={false}
            beforeUnmount={onUmount ? () => onUmount() : undefined}
          >
            {message}
          </ReactBSAlert>
        )
      }
      {
        type === "update" && (
          <ReactBSAlert
              style={{ display: "block", marginTop: "-100px", width: "1000px", overflow: 'visible'}}
              title="Actualiza los datos"
              onConfirm={handleSubmit(this.onSubmit.bind(this))}
              onCancel={() => hideModal()}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              btnSize=""
              showCancel
            >
              <Row>
                <Col md="12">
                  <Row>
                    {
                      data.map((column) => (
                        <Col >
                          <FormGroup >
                            <Label>{column.label}</Label>
                            {
                              column.type === "file" && (
                                <Field
                                  name={column.name}
                                  component={FormFile}
                                  placeholder={column.text}
                                />
                              )
                            } 
                            {
                              column.type === "select" && (
                                <Field
                                  name={column.name}
                                  component={FormSelect}
                                  placeholder={column.text}
                                  options={column.options}
                                  isMulti={column.isMulti}
                                />
                              )
                            } 
                            {
                              column.type === "string" && (
                                <Field
                                  name={column.name}
                                  component={FormInput}
                                  type="text"
                                  placeholder={column.text}
                                />
                              )
                            }
                            {
                              column.type === 'date' && (
                                <Field
                                  name={column.name}
                                  component={FormDate}
                                  placeholder={column.text}
                                />
                              )
                            }
                            {
                              column.type === 'time' && (
                                <Field
                                  name={column.name}
                                  component={FormTime}
                                  placeholder={column.text}
                                />
                              )
                            }
                          </FormGroup>
                        </Col>    
                      )
                      )
                    }
                  </Row>
                </Col>
              </Row>
            </ReactBSAlert>
        )
      }
      </>
    )
  }
}

const Modal = reduxForm({
  form: 'Modal',
})(ModalCore);

export default connect(
  (state) => ({
    type: selectors.getModalType(state),
    message: selectors.getModalMessage(state),
    title: selectors.getModalTitle(state),
    row: selectors.getModalRowData(state),
  }),
  (dispatch) => ({
    hideModal() {
      dispatch(actions.hideModal());
    }
  }),
)(Modal);
