import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import ActivitiesFormModal from '../ActivitiesFormModal';
import ActivitiesModal from '../ActivitiesModal';
import { forwardTo } from '../../history';
import * as selectors from '../../reducers';
import * as actions from '../../actions/activity';
import * as chainsActions from '../../actions/chain';
import * as modalActions from '../../actions/modal';

class Activities extends Component {
  componentDidMount(){
    const {
      fetchActivities,
      fetchAllChains,
    } = this.props;
    fetchActivities();
    fetchAllChains();
  }

  render() {
    const {
      createActivity,
      deleteActivity,
      updateActivity,
      showActivitiesModal,
      showQuestionsModal,
      showVideosfromCourse,
      news,
      chains,
      horario = [
        {
          id: '09:00',
          name: '9 AM'
        },
        {
          id: '15:00',
          name: '3 PM'
        },
      ]
    } = this.props;
    const header = [
      {
        text: 'Imagen',
        name: 'picture',
        type: 'file',
      },
      {
        text: 'Nombre',
        name: 'name',
        type: 'string',
      },
      {
        text: 'Date',
        name: 'limit_date',
        type: 'date',
      },
      {
        text: 'Tiempo',
        name: 'game_time',
        type: 'time',
      },
      {
        text: 'Puntos',
        name: 'quantity_of_points',
        type: 'string',
      },
      {
        text: 'Link',
        name: 'link',
        type: 'string',
        link: true,
      },
      {
        text: 'Intentos',
        name: 'max_entrys',
        type: 'string',
      },
      {
        text: 'Cadena',
        name: 'franchise',
        type: 'select',
        options: chains.map((chain) => {
          return ({
            value:  chain.id,
            label: chain.name,
          })
        }),
      },
      {
        text: 'Preguntas',
        name: 'questions',
        type: 'special',
        hide: true,
        component: ActivitiesFormModal,
      },
      {
        text: 'Fecha',
        name: 'date',
        type: 'date',
      },
      {
        text: 'Horario',
        name: 'time',
        type: 'select',
        options: horario.map((horario) => {
          return ({
            value:  horario.id,
            label: horario.name,
          })
        }),
      },
    ];
    return (
      <>
        <ActivitiesModal />
        <Table
          title="Actividades"
          header={header}
          data={news}
          createAction={createActivity.bind(this)}
          deleteAction={deleteActivity.bind(this)}
          updateAction={updateActivity.bind(this)}
          showModal={showActivitiesModal.bind(this)}
          extraAction2={showVideosfromCourse.bind(this)}
          extraAction={showQuestionsModal.bind(this)}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    news: selectors.getAllActivities(state),
    chains: [
      {
        id:  undefined,
        name: 'TODOS',
      },
      ...selectors.getAllChains(state),
    ],
  }),
  (dispatch) => ({
    fetchAllChains() {
      dispatch(chainsActions.fetchAllChains());
    },
    fetchActivities() {
      dispatch(actions.fetchAllActivities());
    },
    showVideosfromCourse(id, row) {
      forwardTo(`/admin/actividades/${id}`)
    }, 
    createActivity(values) {
      dispatch(actions.createActivity({
        picture: values.picture,
        name: values.name,
        quantity_of_points: values.quantity_of_points,
        game_time: values.game_time ? values.game_time.getHours() * 60 + values.game_time.getMinutes() : 0,
        link: values.link,
        limit_date: `${values.limit_date.getFullYear()}-${values.limit_date.getMonth()+1}-${values.limit_date.getDate()}`,
        franchise: values.franchise,
        questions: values.questions,
        max_entrys: values.max_entrys,
        date: values.date,
        time: values.time
      }))
    },
    deleteActivity(id) {
      dispatch(actions.deleteActivity({
        id,
      }))
    },
    showQuestionsModal(id, data) {
      dispatch(modalActions.showExtra({
        id: id,
        type: "activityModal",
        data: data.questions,
      }));
    },
    showActivitiesModal(id, data){
      dispatch(modalActions.showUpdate({
        id: id,
        data: [
          {
            label: 'Image',
            name: 'picture',
            text: data.picture,
            type: 'file'
          },
          {
            label: 'Nombre',
            name: 'name',
            text: data.name,
            type: 'string'
          },
          {
            label: 'Date',
            name: 'limit_date',
            text: data.limit_date,
            type: 'date'
          },
          {
            label: 'Tiempo',
            name: 'game_time',
            text: data.game_points,
            type: 'time',
          },
          {
            label: 'Intentos',
            name: 'max_entrys',
            text: data.max_entrys,
            type: 'string',
          },
          {
            label: 'Puntos',
            name: 'quantity_of_points',
            text: data.quantity_of_points,
            type: 'string'
          },
          {
            label: 'Link',
            name: 'link',
            text: data.link,
            type: 'string'
          }
        ],
      }))
    },
    updateActivity(values){ 
      dispatch(actions.updateActivity({
        id: values.id,
        picture: values.picture,
        name: values.name,
        limit_date: values.limit_date ? `${values.limit_date.getFullYear()}-${values.limit_date.getMonth()+1}-${values.limit_date.getDate()}` : undefined,
        quantity_of_points: values.quantity_of_points,
        link: values.link,
        max_entrys: values.max_entrys,
        game_time: values.game_time ? values.game_time.getHours() * 60 + values.game_time.getMinutes() : 0,
       
      }))
    },
  })
)(Activities);
