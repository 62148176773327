import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/chain';
import * as api from '../apis/chain';
import * as actions from '../actions/chain';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* chainsFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {

    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllChains,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllChainsConfirm({
        chains: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllChainsDecline({
      message: error.message,
    }));
  }
}

function* chainsCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      path_to_picture,
      name,
      link_to_chains,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createChain,
      token,
      path_to_picture,
      name,
      link_to_chains,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createChainConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllChains());
    }
  } catch (error) {
    yield put(actions.createChainDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* chainsEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteChain,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteChainConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllChains());
    }
  } catch (error) {
    yield put(actions.deleteChainDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* chainsUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      path_to_picture,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.updateChainPicture,
      token,
      id,
      path_to_picture,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteChainConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllChains());
    }
  } catch (error) {
    yield put(actions.deleteChainDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* ChainsSaga() {
  yield takeLatest(
    types.ALL_CHAINS_FETCHED,
    chainsFetcher,
  );
  yield takeLatest(
    types.CHAIN_CREATED,
    chainsCreator,
  );
  yield takeLatest(
    types.CHAIN_DELETED,
    chainsEliminator,
  );
  yield takeLatest(
    types.CHAIN_UPDATED,
    chainsUpdator,
  );
}
