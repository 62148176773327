import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import * as actions from '../../actions/promotion';
import * as selectors from '../../reducers';
import * as modalActions from '../../actions/modal';

const horario = [
  {
    id: '09:00',
    name: '9 AM'
  },
  {
    id: '15:00',
    name: '3 PM'
  }]

const header = [
  {
    text: 'Imagen',
    name: 'path_to_picture',
    type: 'file',
  },
  {
    text: 'Lista dependientes',
    name: 'dpis',
    type: 'file',
    hide: true,
  },
  {
    text: 'Fecha limite',
    name: 'date_to_expire',
    type: 'date',
  },
  {
    text: 'Mensaje',
    name: 'message',
    type: 'string',
  },
  {
    text: 'Titulo',
    name: 'title',
    type: 'string',
  },
  {
    text: 'Fecha',
    name: 'date',
    type: 'date',
  },
  {
    text: 'Horario',
    name: 'time',
    type: 'select',
    options: horario.map((horario) => {
      return ({
        value:  horario.id,
        label: horario.name,
      })
    }),
  },
];

class Promotions extends Component {
  componentDidMount(){
    const {
      fetchAllPromotions
    } = this.props;
    fetchAllPromotions();
  }

  render() {
    const {
      createPromotion,
      deletePromotion,
      updatePromotion,
      promotions,
      showPromotionsModal,
    } = this.props;
    return (
      <Table
        title="Promoción"
        header={header}
        data={promotions}
        createAction={createPromotion.bind(this)}
        deleteAction={deletePromotion.bind(this)}
        showModal={showPromotionsModal.bind(this)}
        updateAction={updatePromotion.bind(this)}
      />
    );
  }
}

export default connect(
  (state) => ({
    promotions: selectors.getAllPromotions(state),
  }),
  (dispatch) => ({
    fetchAllPromotions() {
      dispatch(actions.fetchAllPromotions());
    },
    createPromotion(values) {
      dispatch(modalActions.showLoading({
        title: 'Mensaje',
        message: 'Su archivo se esta leyendo!'
      }));
      dispatch(actions.createPromotion({
        picture: values.path_to_picture,
        dpis: values.dpis,
        date_to_expire: values.date_to_expire ?
        `${values.date_to_expire.getFullYear()}-${values.date_to_expire.getMonth()+1}-${values.date_to_expire.getDate()}` :
        undefined, 
        message: values.message,
        title: values.title,
        date: values.date,
        time: values.time
      }))
    },
    deletePromotion(id) {
      dispatch(actions.deletePromotion({
        id
      }))
    },
    updatePromotion(values){ 
      dispatch(actions.updatePromotion({
        id: values.id,
        path_to_picture: values.path_to_picture,
        date_to_expire: values.date_to_expire ?
          `${values.date_to_expire.getFullYear()}-${values.date_to_expire.getMonth()+1}-${values.date_to_expire.getDate()}` :
          undefined, 
        message: values.message,
        title: values.title,
      }))
    },
    showPromotionsModal(id, data) {
      dispatch(modalActions.showUpdate({
        id: id,
        data: [
          {
            label: 'Image',
            name: 'path_to_picture',
            text: data.path_to_picture,
            type: 'file'
          },
          {
            label: 'Fecha',
            name: 'date_to_expire',
            text: data.date_to_expire,
            type: 'date'
          },
          {
            label: 'Mensaje',
            text: data.message,
            name: 'message',
            type: 'string',
          },
          {
            label: 'Titulo',
            text: data.title,
            name: 'title',
            type: 'string',
          },
        ],
      }))
    },
  })
)(Promotions);
