import * as types from '../types/benefit';

export const fetchAllBenefits = () => ({
  type: types.ALL_BENEFITS_FETCHED,
  payload: {},
});

export const fetchAllBenefitsConfirm = ({
  benefits,
}) => ({
  type: types.ALL_BENEFITS_FETCHED_SUCCEEDED,
  payload: {
    benefits,
  },
});

export const fetchAllBenefitsDecline = ({
  message,
}) => ({
  type: types.ALL_BENEFITS_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const fetchAllLevels = () => ({
  type: types.ALL_LEVELS_FETCHED,
  payload: {},
});

export const fetchAllLevelsConfirm = ({
  levels,
}) => ({
  type: types.ALL_LEVELS_FETCHED_SUCCEEDED,
  payload: {
    levels,
  },
});

export const fetchAllLevelsDecline = ({
  message,
}) => ({
  type: types.ALL_LEVELS_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const createBenefit = ({
  picture,
  name,
  price,
  level,
  category,
  exchange_place,
  type,
}) => ({
  type: types.BENEFIT_CREATED,
  payload: {
    picture,
    name,
    price,
    level,
    category,
    exchange_place,
    type,
  },
});

export const createBenefitConfirm = () => ({
  type: types.BENEFIT_CREATED_SUCCEEDED,
  payload: {},
});

export const createBenefitDecline = ({
  message,
}) => ({
  type: types.BENEFIT_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteBenefit = ({
  id,
}) => ({
  type: types.BENEFIT_DELETED,
  payload: {
    id,
  },
});

export const deleteBenefitConfirm = ({
  data,
}) => ({
  type: types.BENEFIT_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteBenefitDecline = ({
  message,
}) => ({
  type: types.BENEFIT_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateBenefit = ({
  id,
  picture,
  name,
  price,
  level,
  category,
  exchange_place,
  type,
}) => ({
  type: types.BENEFIT_UPDATED,
  payload: {
    id,
    picture,
    name,
    price,
    level,
    category,
    exchange_place,
    type,
  },
});

export const updateBenefitConfirm = ({
  data,
}) => ({
  type: types.BENEFIT_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateBenefitDecline = ({
  message,
}) => ({
  type: types.BENEFIT_UPDATED_FAILED,
  payload: {
    message,
  },
});
