import * as types from '../types/category';

export const fetchAllCategories = () => ({
  type: types.ALL_CATEGORIES_FETCHED,
  payload: {},
});

export const fetchAllCategoriesConfirm = ({
  categories,
}) => ({
  type: types.ALL_CATEGORIES_FETCHED_SUCCEEDED,
  payload: {
    categories,
  },
});

export const fetchAllCategoriesDecline = ({
  message,
}) => ({
  type: types.ALL_CATEGORIES_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const fetchAllPrizesCategories = () => ({
  type: types.ALL_PRIZES_CATEGORIES_FETCHED,
  payload: {},
})

export const fetchAllPrizesCategoriesConfirm = ({
  categories,
}) => ({
  type: types.ALL_PRIZES_CATEGORIES_FETCHED_SUCCEEDED,
  payload: {
    categories,
  },
});

export const fetchAllPrizesCategoriesDecline = ({
  message,
}) => ({
  type: types.ALL_PRIZES_CATEGORIES_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const fetchAllBenefitsCategories = () => ({
  type: types.ALL_BENEFITS_CATEGORIES_FETCHED,
  payload: {},
})

export const fetchAllBenefitsCategoriesConfirm = ({
  categories,
}) => ({
  type: types.ALL_BENEFITS_CATEGORIES_FETCHED_SUCCEEDED,
  payload: {
    categories,
  },
});

export const fetchAllBenefitsCategoriesDecline = ({
  message,
}) => ({
  type: types.ALL_BENEFITS_CATEGORIES_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const createCategory = ({
  path_to_picture,
  path_to_icon,
  name,
  priority,
  for_prize
}) => ({
  type: types.CATEGORY_CREATED,
  payload: {
    path_to_picture,
    path_to_icon,
    name,
    priority,
    for_prize,
  },
});

export const createCategoryConfirm = () => ({
  type: types.CATEGORY_CREATED_SUCCEEDED,
  payload: {},
});

export const createCategoryDecline = ({
  message,
}) => ({
  type: types.CATEGORY_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteCategory = ({
  id,
}) => ({
  type: types.CATEGORY_DELETED,
  payload: {
    id,
  },
});

export const deleteCategoryConfirm = ({
  data,
}) => ({
  type: types.CATEGORY_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteCategoryDecline = ({
  message,
}) => ({
  type: types.CATEGORY_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateCategory = ({
  id,
  path_to_picture,
  path_to_icon,
  name,
  priority,
}) => ({
  type: types.CATEGORY_UPDATED,
  payload: {
    id,
    path_to_picture,
    path_to_icon,
    name,
    priority,
  },
});

export const updateCategoryConfirm = ({
  data,
}) => ({
  type: types.CATEGORY_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateCategoryDecline = ({
  message,
}) => ({
  type: types.CATEGORY_UPDATED_FAILED,
  payload: {
    message,
  },
});
