import * as types from '../types/observer';

export const fetchAllObservers = ({
  fromDate,
  toDate,
}) => ({
  type: types.ALL_OBSERVERS_FETCHED,
  payload: {
    fromDate,
    toDate,
  },
});

export const fetchAllObserversConfirm = ({
  observers,
}) => ({
  type: types.ALL_OBSERVERS_FETCHED_SUCCEEDED,
  payload: {
    observers,
  },
});

export const fetchAllObserversDecline = ({
  message,
}) => ({
  type: types.ALL_OBSERVERS_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const downloadAllObservers = ({
  fromDate,
  toDate,
}) => ({
  type: types.ALL_OBSERVERS_DOWNLOADED,
  payload: {
    fromDate,
    toDate,
  },
});

export const downloadAllObserversConfirm = ({}) => ({
  type: types.ALL_OBSERVERS_DOWNLOADED_SUCCEEDED,
  payload: {},
});

export const downloadAllObserversDecline = ({
  message,
}) => ({
  type: types.ALL_OBSERVERS_DOWNLOADED_FAILED,
  payload: {
    message,
  },
});

export const createObserver = ({
  name,
  link_to_observers,
}) => ({
  type: types.OBSERVER_CREATED,
  payload: {
    name,
    link_to_observers,
  },
});

export const createObserverConfirm = () => ({
  type: types.OBSERVER_CREATED_SUCCEEDED,
  payload: {},
});

export const createObserverDecline = ({
  message,
}) => ({
  type: types.OBSERVER_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteObserver = ({
  id,
}) => ({
  type: types.OBSERVER_DELETED,
  payload: {
    id,
  },
});

export const deleteObserverConfirm = ({
  data,
}) => ({
  type: types.OBSERVER_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteObserverDecline = ({
  message,
}) => ({
  type: types.OBSERVER_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateObserver = ({
  id,
  name,
  link_to_observers,
}) => ({
  type: types.OBSERVER_UPDATED,
  payload: {
    id,
    name,
    link_to_observers,
  },
});

export const updateObserverConfirm = ({
  data,
}) => ({
  type: types.OBSERVER_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateObserverDecline = ({
  message,
}) => ({
  type: types.OBSERVER_UPDATED_FAILED,
  payload: {
    message,
  },
});
