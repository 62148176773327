import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import * as selectors from '../../reducers';
import * as actions from '../../actions/product';
import * as chainActions from '../../actions/chain';
import * as modalActions from '../../actions/modal';

class Products extends Component {
  
  componentDidMount(){
    const {
      fetchProducts,
      fetchChains,
    } = this.props;
    fetchProducts();
    fetchChains();
    
  }
  
  
  render() {
    const {
      products,
      createProduct,
      deleteProduct,
      updateProduct,
      showProductModal,
      chains,
    } = this.props;

    const header = [
      {
        text: 'Imagen',
        name: 'path_to_picture',
        type: 'file',
      },
      {
        name: 'name',
        text: 'Nombre',
        type: 'string',
      },
      {
        name: 'coins',
        text: 'Puntos',
        type: 'string',
      },
      {
        name: 'franchise_ignore',
        text: 'Ignorar Franquicias',
        type: 'select',
        options: chains.map(chain => ({
          value: chain.id,
          label: chain.name,
        })),
        isMulti: true,
      }
    ];

    return (
      <Table
        title="Productos"
        header={header}
        data={products}
        createAction={createProduct.bind(this)}
        deleteAction={deleteProduct.bind(this)}
        showModal={showProductModal.bind(this)}
        updateAction={updateProduct.bind(this)}
      />
  );
  }
}

export default connect(
  (state) => ({
    products: selectors.getAllProducts(state),
    chains: [
      {
        id:  undefined,
        name: 'TODOS',
      },
      ...selectors.getAllChains(state)
    ],
  }),
  (dispatch) => ({
    fetchProducts() {
      dispatch(actions.fetchAllProducts())
    },
    fetchChains() {
      dispatch(chainActions.fetchAllChains())
    },
    createProduct(values) {
      dispatch(actions.createProduct({
        path_to_picture: values.path_to_picture,
        name: values.name,
        coins: values.coins,
        franchise_ignore: values.franchise_ignore,
      }));
    },
    deleteProduct(id) {
      dispatch(actions.deleteProduct({
        id,
      }))
    },
    showProductModal(id, data, franchiseOptions) {
      dispatch(modalActions.showUpdate({
        id: id,
        data: [
          {
            label: 'Image',
            name: 'path_to_picture',
            text: data.path_to_picture,
            type: 'file'
          },
          {
            label: 'Nombre',
            name: 'name',
            text: data.name,
            type: 'string'
          },
          {
            label: 'Puntos',
            name: 'coins',
            text: data.coins,
            type: 'string'
          },
          {
            label: 'Ignorar Franquicias',
            name: 'franchise_ignore',
            text: data.franchise_ignore,
            type: 'select',
            isMulti: true,
            options: franchiseOptions,
          },
        ],
      }))
    },
    updateProduct(values){ 
      dispatch(actions.updateProduct({
        id: values.id,
        path_to_picture: values.path_to_picture,
        name: values.name,
        coins: values.coins,
        franchise_ignore: values.franchise_ignore,
      }))
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    showProductModal: (id, data) => dispatchProps.showProductModal(id, data, stateProps.chains.map(chain => ({
      value: chain.id,
      label: chain.name,
    })) ),
  })
)(Products);
