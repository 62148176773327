export const ALL_CATEGORIES_FETCHED = 'ALL_CATEGORIES_FETCHED';
export const ALL_CATEGORIES_FETCHED_SUCCEEDED = 'ALL_CATEGORIES_FETCHED_SUCCEEDED';
export const ALL_CATEGORIES_FETCHED_FAILED = 'ALL_CATEGORIES_FETCHED_FAILED';

export const ALL_PRIZES_CATEGORIES_FETCHED = 'ALL_PRIZES_CATEGORIES_FETCHED';
export const ALL_PRIZES_CATEGORIES_FETCHED_SUCCEEDED = 'ALL_PRIZES_CATEGORIES_FETCHED_SUCCEEDED';
export const ALL_PRIZES_CATEGORIES_FETCHED_FAILED = 'ALL_PRIZES_CATEGORIES_FETCHED_FAILED';

export const ALL_BENEFITS_CATEGORIES_FETCHED = 'ALL_BENEFITS_CATEGORIES_FETCHED';
export const ALL_BENEFITS_CATEGORIES_FETCHED_SUCCEEDED = 'ALL_BENEFITS_CATEGORIES_FETCHED_SUCCEEDED';
export const ALL_BENEFITS_CATEGORIES_FETCHED_FAILED = 'ALL_BENEFITS_CATEGORIES_FETCHED_FAILED';

export const CATEGORY_CREATED = 'CATEGORY_CREATED';
export const CATEGORY_CREATED_SUCCEEDED = 'CATEGORY_CREATED_SUCCEEDED';
export const CATEGORY_CREATED_FAILED = 'CATEGORY_CREATED_FAILED';

export const CATEGORY_DELETED = 'CATEGORY_DELETED';
export const CATEGORY_DELETED_SUCCEEDED = 'CATEGORY_DELETED_SUCCEEDED';
export const CATEGORY_DELETED_FAILED = 'CATEGORY_DELETED_FAILED';

export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';
export const CATEGORY_UPDATED_SUCCEEDED = 'CATEGORY_UPDATED_SUCCEEDED';
export const CATEGORY_UPDATED_FAILED = 'CATEGORY_UPDATED_FAILED';
