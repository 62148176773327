import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Person from "@material-ui/icons/Person";
import AccountTree from "@material-ui/icons/AccountTree";
import Headset from "@material-ui/icons/Headset";
import Cast from "@material-ui/icons/Cast";
import Ballot from "@material-ui/icons/Ballot";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import Loyalty from "@material-ui/icons/Loyalty";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Business from "@material-ui/icons/Business";
import AccountBox from "@material-ui/icons/AccountBox";
import Assistant from "@material-ui/icons/Assistant";

import Sidebar from '../Sidebar';
import NavBar from '../Navbar';
import Users from '../Users';
import Categories from '../Categories';
import Benefits from '../Benefits';
import Prizes from '../Prizes';
import Exchanges from '../Exchanges';
import ExchangesPrizes from '../ExchangesPrizes';
import Observers from '../Observers';
import Promotions from '../Promotions';
import News from '../News';
import Activities from '../Activities';
import Products from '../Products';
import Branches from '../Branches';
import Chains from '../Chains';
import ActivityHistory from '../ActivityHistory';
import * as loginActions from '../../actions/login';

import logo from "assets/img/logo_innfarma.jpg";
import image from "assets/img/sidebar-2.jpg";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

const useStyles = makeStyles(styles);

const routes = [
  {
    component: Users,
    icon: Person,
    layout: "/admin",
    name: "Dependientes",
    path: "/dependientes",
  },
  {
    component: Categories,
    icon: AccountTree,
    layout: "/admin",
    name: "Categorias",
    path: "/categorias",
  },
  {
    component: Prizes,
    icon: Headset,
    layout: "/admin",
    name: "Premios",
    path: "/Premios",
  },
  {
    component: ExchangesPrizes,
    icon: Assistant,
    layout: "/admin",
    name: "Canje de premios",
    path: "/canjearpremios",
  },
  {
    component: Benefits,
    icon: Headset,
    layout: "/admin",
    name: "Beneficios",
    path: "/beneficios",
  },
  {
    component: Exchanges,
    icon: Assistant,
    layout: "/admin",
    name: "Canje de beneficios",
    path: "/canjes",
  },
  {
    component: News,
    icon: Cast,
    layout: "/admin",
    name: "Noticias",
    path: "/noticias",
  },
  {
    component: Promotions,
    icon: Loyalty,
    layout: "/admin",
    name: "Promociones",
    path: "/promociones",
  },
  {
    component: Activities,
    icon: Ballot,
    layout: "/admin",
    name: "Actividades",
    path: "/actividades",
  },
  {
    component: Products,
    icon: BusinessCenter,
    layout: "/admin",
    name: "Productos",
    path: "/productos",
  },
  {
    component: Chains,
    icon: Business,
    layout: "/admin",
    name: "Cadenas",
    path: "/cadenas",
  },
  {
    component: Observers,
    icon: AccountTree,
    layout: "/admin",
    name: "Observador",
    path: "/observador",
  },
  {
    component: Branches,
    icon: AccountBox,
    layout: "/admin",
    name: "Sucursales",
    path: "/sucursales",
  },
  {
    component: Products,
    icon: MeetingRoom,
    layout: "/logout",
    name: "Salir",
    path: "/",
  },
];

const getRoute = () => {
  return window.location.pathname !== "/admin/maps";
};

const switchRoutes = (
  <Switch>
    <Route
      path={"/admin/actividades/:id"} 
      component={ActivityHistory}
    />
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/" to="/admin/dependientes" />
  </Switch>
);

const Layout = ({...rest}) => {
  const classes = useStyles();
  return (
    <>
      <Sidebar
        routes={routes}
        logo={logo}
        image={image}
        color="red"
        {...rest}
      />
      <div className={classes.mainPanel}>
        <NavBar
          routes={routes}
          {...rest}
        />
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <div /> : null}
      </div>
    </>
  )
}

export default compose(
  withRouter,
  connect(
    undefined,
    (dispatch) => ({
      logout(){
        dispatch(loginActions.userLogout())
      }
    })
  )
)(Layout);
