import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/user';
import * as api from '../apis/user';
import * as actions from '../actions/user';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* usersFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      name,
      erpId,
    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllUsers,
      token,
      name,
      erpId
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllUsersConfirm({
        users: response.data,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllUsersDecline({
      message: error.message,
    }));
  }
}

function* usersOneFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id
    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchOneUser,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchOneUsersConfirm({
        users: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchOneUsersDecline({
      message: error.message,
    }));
  }
}

function* usersDownloader(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      name,
      erpId,
    },
  } = action;
  try {
    const { logout } = yield call(
      api.downloadAllUsers,
      token,
      name,
      erpId
    );
    yield put(modalActions.hideModal());
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(modalActions.showSuccess({}));
      yield put(actions.downloadAllUsersConfirm({}));
    }
  } catch (error) {
    yield put(modalActions.showError({}));
    yield put(actions.downloadAllUsersDecline({
      message: error.message,
    }));
  }
}

function* usersConfirmator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      erp_id,
      level,
      status,
      coins,
      dpi,
      email,
      birthday,
      phone,
      points,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.confirmUser,
      token,
      id,
      erp_id,
      level,
      status,
      coins,
      dpi,
      email,
      birthday,
      phone,
      points
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.confirmUserConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllUsers({}));
    }
  } catch (error) {
    yield put(actions.confirmUserDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* usersEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteUser,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteUserConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllUsers({}));
    }
  } catch (error) {
    yield put(actions.deleteUserDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* usersUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      name,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.updateUser,
      token,
      name,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteUserConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllUsers({}));
    }
  } catch (error) {
    yield put(actions.deleteUserDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* UsersSaga() {
  yield takeLatest(
    types.ALL_USERS_FETCHED,
    usersFetcher,
  );
  yield takeLatest(
    types.ONE_USER_FETCHED,
    usersOneFetcher,
  );
  yield takeLatest(
    types.ALL_USERS_DOWNLOADED,
    usersDownloader,
  );
  yield takeLatest(
    types.USER_CONFIRMED,
    usersConfirmator,
  );
  yield takeLatest(
    types.USER_DELETED,
    usersEliminator,
  );
  yield takeLatest(
    types.USER_UPDATED,
    usersUpdator,
  );
}
