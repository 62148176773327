import * as types from '../types/exchangePrize';

export const fetchAllExchangesPrizes = ({
  fromDate,
  toDate,
}) => ({
  type: types.ALL_EXCHANGESPRIZES_FETCHED,
  payload: {
    fromDate,
    toDate,
  },
});

export const fetchAllExchangesPrizesConfirm = ({
  exchangesPrizes,
}) => ({
  type: types.ALL_EXCHANGESPRIZES_FETCHED_SUCCEEDED,
  payload: {
    exchangesPrizes,
  },
});

export const fetchAllExchangesPrizesDecline = ({
  message,
}) => ({
  type: types.ALL_EXCHANGESPRIZES_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const downloadAllExchangesPrizes = ({
  fromDate,
  toDate,
}) => ({
  type: types.ALL_EXCHANGESPRIZES_DOWNLOADED,
  payload: {
    fromDate,
    toDate,
  },
});

export const downloadAllExchangesPrizesConfirm = ({}) => ({
  type: types.ALL_EXCHANGESPRIZES_DOWNLOADED_SUCCEEDED,
  payload: {},
});

export const downloadAllExchangesPrizesDecline = ({
  message,
}) => ({
  type: types.ALL_EXCHANGESPRIZES_DOWNLOADED_FAILED,
  payload: {
    message,
  },
});

export const createExchangePrize = ({
  path_to_picture,
  name,
  link_to_exchanges,
}) => ({
  type: types.EXCHANGEPRIZE_CREATED,
  payload: {
    path_to_picture,
    name,
    link_to_exchanges,
  },
});

export const createExchangePrizeConfirm = () => ({
  type: types.EXCHANGEPRIZE_CREATED_SUCCEEDED,
  payload: {},
});

export const createExchangePrizeDecline = ({
  message,
}) => ({
  type: types.EXCHANGEPRIZE_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteExchangePrize = ({
  id,
}) => ({
  type: types.EXCHANGEPRIZE_DELETED,
  payload: {
    id,
  },
});

export const deleteExchangePrizeConfirm = ({
  data,
}) => ({
  type: types.EXCHANGEPRIZE_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteExchangePrizeDecline = ({
  message,
}) => ({
  type: types.EXCHANGEPRIZE_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateExchangePrize = ({
  id,
  path_to_picture,
  name,
  link_to_exchanges,
}) => ({
  type: types.EXCHANGEPRIZE_UPDATED,
  payload: {
    id,
    path_to_picture,
    name,
    link_to_exchanges,
  },
});

export const updateExchangePrizeConfirm = ({
  data,
}) => ({
  type: types.EXCHANGEPRIZE_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateExchangePrizeDecline = ({
  message,
}) => ({
  type: types.EXCHANGEPRIZE_UPDATED_FAILED,
  payload: {
    message,
  },
});
