import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

const FormInput = ({
  input: { onChange },
  placeholder,
  type,
  value,
  icon,
  action
}) => (
  /**
  <Input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={({ target }) => onChange(target.value)}
    autoFocus={false}
    formNoValidate
  />*/
  <>
    <Button 
      color="info"
      onClick={() => action()}
    >
      Seleccionar(2)
    </Button>
  </>
);

FormInput.propTypes = {
  // cambiar a un objeto
  input: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default FormInput;
