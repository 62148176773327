import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Field, reduxForm } from 'redux-form';
import Table from '../General/Table';
import FormSelect from '../General/FormSelect';
import * as selectors from '../../reducers';
import * as actions from '../../actions/branch';
import * as chainActions from '../../actions/chain';

const header = [
  {
    text: 'Nombre',
    name: 'name',
    type: 'string',
  },
  {
    text: 'Departamento',
    name: 'department',
    type: 'string',
  },
  {
    text: 'Municipio',
    name: 'municipality',
    type: 'string',
  },
  
];

class Branches extends Component {
  componentDidMount(){
    const {
      //fetchBranches,
      fetchChaines,
    } = this.props;
    //fetchBranches();
    fetchChaines();
  }

  render() {
    const {
      branches,
      chains,
      handleSubmit,
      fetchBranches,
    } = this.props;
    return (
      <>
        <Card>
          <CardBody>
            <form onSubmit={handleSubmit(fetchBranches.bind(this))}>
              <CardTitle>
                Selecciona la Cadena
              </CardTitle>
              <Row>
                <Col sm="10">
                  <Row>
                    <Col sm="3" style={{marginTop: '10px'}}>
                      <Field
                        name="chain"
                        component={FormSelect}
                        //action={() => column.showAction()}
                        options={chains.map((chain) => {
                          return ({
                            value:  chain.id,
                            label: chain.name,
                          })
                        })}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
        <Table
          title="Productos"
          header={header}
          data={branches}
        />
      </>
  );
  }
}

export default connect(
  (state) => ({
    branches: selectors.getAllBranchs(state),
    chains: [
      ...selectors.getAllChains(state),
    ],
  }),
  (dispatch) => ({
    fetchBranches(chainId) {
      dispatch(actions.fetchAllBranchs({
        chainId: chainId.chain
      }))
    },
    fetchChaines() {
      dispatch(chainActions.fetchAllChains())
    },
  })
)(reduxForm({
  form: "test"
  })(Branches));
