import URL from './routes';

export const fetchAllObservers = (
  token,
  fromDate,
  toDate,
) => new Promise((resolve, reject) => {
  console.log(fromDate)
  const params = fromDate && toDate ? `?first_date=${fromDate.toISOString().slice(0, 24)}&last_date=${toDate.toISOString().slice(0, 24)}` : ''
  console.log(params)
  console.log(fromDate)
  fetch(`${URL}/user/get_observers_admin/${params}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const downloadAllObservers = (
  token,
  fromDate,
  toDate,
) => new Promise((resolve, reject) => {
  const params = fromDate && toDate ? `?first_date=${fromDate.toISOString().slice(0, 24)}&last_date=${toDate.toISOString().slice(0, 24)}` : ''
  fetch(`${URL}/user/get_observer_report/${params}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.blob()
      .then( blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const juntado = `Observador ${fromDate.toISOString().slice(0, 10)} - ${toDate.toISOString().slice(0, 10)}.xlsx`
        a.download = juntado
        document.body.appendChild(a); 
        a.click();    
        a.remove(); 
      });
    } else {
      resultado.json().then((res) => resolve({
        response: res,
        error: true,
        logout: resultado.status === 401,
      }));
    }
  }).catch((error) => reject(error));
});

export const createObserver = (
  token,
  name,
  link_to_observer,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  name && data.append('name', name);
  link_to_observer && data.append('link', link_to_observer);
  fetch(`${URL}/user/action_log/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const deleteObserver = (
  token,
  id,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/user/action_log/${id}/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resolve({
        logout: false,
      });
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateObserver = (
  token,
  id,
  name,
  link_to_observer,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  name && data.append('name', name);
  link_to_observer && data.append('link_to_observer', link_to_observer);
  fetch(`${URL}/user/action_log/${id}/`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 
