
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import * as selectors from '../../reducers';
import * as actions from '../../actions/benefit';
import * as categoryActions from '../../actions/category';
import * as modalActions from '../../actions/modal';

class Benefits extends Component {
  componentDidMount(){
    const {
      fetchAllBenefits,
      fetchAlLCategories,
      fetchAllLevels,
    } = this.props;
    fetchAllBenefits();
    fetchAlLCategories();
    fetchAllLevels();
  }

  render() {
    const {
      createBenefit,
      deleteBenefit,
      updateBenefit,
      benefits,
      categories,
      showBenefitModal,
      levels,
    } = this.props;
      console.log("🚀 ~ file: index.jsx ~ line 32 ~ Benefits ~ render ~ benefits", benefits)
    const header = [
      {
        text: 'Imagen',
        name: 'picture',
        type: 'file',
      },
      {
        text: 'Nombre',
        name: 'name',
        type: 'string',
      },
      {
        text: 'Costo(monedas)',
        name: 'price',
        type: 'string',
      },
      // {
      //   text: 'Nivel',
      //   name: 'level',
      //   type: 'select',
      //   options: levels.map((level) => ({
      //     value:  level.id,
      //     label: level.name,
      //   })
      // ),
      // },
      {
        text: 'Categoria',
        name: 'categor', //truena con category
        type: 'select',
        options: categories.map((category) => ({
            value:  category.id,
            label: category.name,
          })
        ),
      },
      {
        text: 'Lugar de canje',
        name: 'exchange_place',
        type: 'select',
        options: [
          {
            value: 'PH',
            label: 'Farmacia',
          },
          {
            value: 'PL',
            label: 'Establecimiento',
          },
        ],
      },
      {
        text: 'Tipo',
        name: 'type',
        type: 'select',
        options: [
          {
            value: 'ONE',
            label: 'Una vez',
          },
          {
            value: 'MULTI',
            label: 'Varias',
          },
        ],
      },
    ];

    return (
      <Table
        title="Beneficio"
        header={header}
        data={benefits}
        createAction={createBenefit.bind(this)}
        deleteAction={deleteBenefit.bind(this)}
        showModal={showBenefitModal.bind(this)}
        updateAction={updateBenefit.bind(this)}
      />
    );
  }
}

export default connect(
  (state) => ({
    benefits: selectors.getAllBenefits(state),
    levels: selectors.getAllLevels(state),
    categories: selectors.getAllCategories(state),
  }),
  (dispatch) => ({
    fetchAllBenefits() {
      dispatch(actions.fetchAllBenefits());
    },
    fetchAllLevels() {
      dispatch(actions.fetchAllLevels());
    },
    fetchAlLCategories() {
      dispatch(categoryActions.fetchAllBenefitsCategories());
    },
    createBenefit(values) {
      dispatch(actions.createBenefit({
        picture: values.picture,
        name: values.name,
        price: values.price,
        level: values.level,
        category: values.categor,
        exchange_place: values.exchange_place,
        type: values.type,
      }));
    },
    deleteBenefit(id) {
      dispatch(actions.deleteBenefit({
        id,
      }))
    },
    showBenefitModalCore(id, data, categories, levels) {
      dispatch(modalActions.showUpdate({
        id: id,
        data: [
          {
            label: 'Image',
            name: 'picture',
            text: data.path_to_picture,
            type: 'file'
          },
          {
            label: 'Nombre',
            name: 'name',
            text: data.name,
            type: 'string'
          },
          {
            label: 'Costo(monedas)',
            text: data.price,
            name: 'price',
            type: 'string',
          },
          // {
          //   label: 'Nivel',
          //   text: data.level,
          //   name: 'level',
          //   type: 'select',
          //   options: levels.map((level) => ({
          //       value:  level.id,
          //       label: level.name,
          //     })
          //   ),
          // },
          {
            label: 'Categoria',
            text: data.categor,
            name: 'categor', //truena con category
            type: 'select',
            options: categories.map((category) => ({
                value:  category.id,
                label: category.name,
              })
            ),
          },
          {
            label: 'Lugar de canje',
            text: data.exchange_place,
            name: 'exchange_place',
            type: 'select',
            options: [
              {
                value: 'PH',
                label: 'Farmacia',
              },
              {
                value: 'PL',
                label: 'Establecimiento',
              },
            ],
          },
          {
            label: 'Tipo',
            text: data.type,
            name: 'type',
            type: 'select',
            options: [
              {
                value: 'ONE',
                label: 'Una vez',
              },
              {
                value: 'MULTI',
                label: 'Varias',
              },
            ],
          },
        ],
      }))
    },
    updateBenefit(values){ 
      dispatch(actions.updateBenefit({
        id: values.id,
        picture: values.picture,
        name: values.name,
        price: values.price,
        level: values.level,
        category: values.categor,
        exchange_place: values.exchange_place,
        type: values.type,
      }))
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    showBenefitModal(id, data) {
      dispatchProps.showBenefitModalCore(id, data, stateProps.categories, stateProps.levels);
    },
  })
)(Benefits);
