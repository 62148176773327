import React from "react";
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import FormInput from '../FormInput';
import FormFile from '../FormFile';
import FormSelect from '../FormSelect';
import FormOptions from '../FormOptions';
import FormDate from '../FormDate';
import FormTime from '../FormTime';
import Modal from '../Modal';
import * as modalActions from '../../../actions/modal';

class ExtendedTables extends React.Component {
  onSubmit(values) {
    const {
      createAction
    } = this.props;
    createAction(values);
  }
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      itemsPerPage: 40,
    }
  }




  render() {
    const {
      data,
      header,
      title,
      createAction,
      deleteAction,
      updateAction,
      showModal,
      extraAction,
      extraAction2,
      handleSubmit,
    } = this.props;
    const totalPages = Math.ceil(data.length / this.state.itemsPerPage);
    const startIndex = (this.state.currentPage - 1) * this.state.itemsPerPage;
    const endIndex = startIndex + this.state.itemsPerPage;
    const slicedData = data.slice(startIndex, endIndex);
    return (
      <>
        {
          updateAction ? (
            <Modal updateAction={updateAction.bind(this)} />
          ) : (
            <Modal />
          )
        }
        {
          createAction && (
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                  <CardTitle>
                    Agregar {title}
                  </CardTitle>
                  <Row>
                    <Col sm="10">
                      <Row>
                        {
                          header.map((column) => (
                            <Col sm="3" style={{ marginTop: '10px' }}>
                              {
                                column.type === "file" && (
                                  <Field
                                    name={column.name}
                                    component={FormFile}
                                    placeholder={column.text}
                                  />
                                )
                              }
                              {
                                column.type === "select" && (
                                  <Field
                                    name={column.name}
                                    component={FormSelect}
                                    placeholder={column.text}
                                    options={column.options}
                                    isMulti={column.isMulti}
                                  />
                                )
                              }
                              {
                                column.type === "string" && (
                                  <Field
                                    name={column.name}
                                    component={FormInput}
                                    type="text"
                                    placeholder={column.text}
                                  />
                                )
                              }
                              {
                                column.type === "options" && (
                                  <Field
                                    name={column.name}
                                    component={FormOptions}
                                    action={() => column.showAction()}
                                  />
                                )
                              }
                              {
                                column.type === 'date' && (
                                  <Field
                                    name={column.name}
                                    component={FormDate}
                                    placeholder={column.text}
                                  />
                                )
                              }
                              {
                                column.type === 'time' && (
                                  <Field
                                    name={column.name}
                                    component={FormTime}
                                    placeholder={column.text}
                                  />
                                )
                              }
                              {
                                column.type === 'special' && (
                                  <Field
                                    name={column.name}
                                    component={column.component}
                                    placeholder={column.text}
                                  />
                                )
                              }
                            </Col>
                          ))
                        }
                      </Row>
                    </Col>
                    <Col sm="2">
                      <Button
                        color="info"
                        type="submit"
                      >
                        Agregar
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          )
        }
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th />

                      {
                        header.map((column) => (
                          <>
                            {
                              !column.hide && (
                                <th className="text-center">
                                  {column.text}
                                </th>
                              )
                            }
                          </>
                        ))
                      }
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      slicedData.map((row) => (
                        <tr key={row.id}>
                          <td className="text-center">
                            {
                              /**
                              extraAction && (
                                <>
                                  <Button
                                    className="btn-link btn-icon"
                                    color="success"
                                    id="tooltip324367706"
                                    size="sm"
                                    onClick={() => extraAction(row.id)}
                                  >
                                    <i className="tim-icons icon-pencil" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip324367706"
                                  >
                                    Tooltip on top
                                  </UncontrolledTooltip>
                                </>
                              ) 
                               */
                            }
                          </td>
                          {
                            header.map((column) => (
                              <>
                                {
                                  !column.hide && column.type === 'file' && (
                                    <td className="text-center">
                                      <div className="photo">
                                        <img
                                          alt="..."
                                          src={row[column.name]}
                                        />
                                      </div>
                                    </td>
                                  )
                                }
                                {
                                  (!column.hide && (
                                    column.type === 'string' ||
                                    column.type === 'select' ||
                                    column.type === 'date' ||
                                    column.type === 'time' ||
                                    column.type === undefined
                                  )
                                  ) && (
                                    <td className="text-center">
                                      {
                                        column.link ? (
                                          <a
                                            href={row[column.name]}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {row[column.name]}
                                          </a>
                                        ) : (
                                          <p>
                                            {Array.isArray(row[column.name]) ? row[column.name].join(', ') : (row[column.name] === true || row[column.name] === false) ? row[column.name] ? 'Premio' : 'Beneficio' : row[column.name]}
                                          </p>
                                        )
                                      }
                                    </td>
                                  )
                                }
                              </>
                            ))
                          }
                          <td className="text-center">
                            {
                              updateAction && (
                                <>
                                  <Button
                                    className="btn-link btn-icon"
                                    color="success"
                                    id="tooltip324367706"
                                    size="sm"
                                    //onClick={() => showUpdateModal(row.id)}
                                    onClick={() => showModal(row.id, row)}
                                  >
                                    <i className="tim-icons icon-pencil" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip324367706"
                                  >
                                    Actualizar
                                  </UncontrolledTooltip>
                                </>
                              )
                            }
                            {
                              extraAction && (
                                <>
                                  <Button
                                    className="btn-link btn-icon"
                                    color="blue"
                                    id="tooltip324367705"
                                    size="sm"
                                    onClick={() => extraAction(row.id, row)}
                                  >
                                    <i className="tim-icons icon-zoom-split" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip324367705"
                                  >
                                    Ver Detalle
                                  </UncontrolledTooltip>
                                </>
                              )
                            }
                            {
                              extraAction2 && (
                                <>
                                  <Button
                                    className="btn-link btn-icon"
                                    color="blue"
                                    id="tooltip324367705"
                                    size="sm"
                                    onClick={() => extraAction2(row.id, row)}
                                  >
                                    <i className="tim-icons icon-cloud-download-93" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip324367705"
                                  >
                                    Ver Detalle
                                  </UncontrolledTooltip>
                                </>
                              )
                            }
                            {
                              deleteAction && (
                                <>
                                  <Button
                                    className="btn-link"
                                    color="danger"
                                    id="tooltip974171201"
                                    size="sm"
                                    onClick={() => deleteAction(row.id)}
                                  >
                                    <i className="tim-icons icon-simple-remove" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip974171201"
                                  >
                                    Eliminar
                                  </UncontrolledTooltip>
                                </>
                              )
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                {/* Paginación de reactstrap */}
                <Pagination>
                  <PaginationItem disabled={this.state.currentPage === 1}>
                    <PaginationLink previous onClick={(e) => this.setState({ currentPage : this.state.currentPage - 1})} />
                  </PaginationItem>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <PaginationItem key={index} active={this.state.currentPage === index + 1}>
                      <PaginationLink onClick={() => this.setState({currentPage : index + 1})}>
                        {index + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={this.state.currentPage === totalPages}>
                    <PaginationLink next onClick={() => this.setState({currentPage : this.state.currentPage + 1})} />
                  </PaginationItem>
                </Pagination>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default compose(
  reduxForm({
    form: "tableForm"
  }),
  connect(
    undefined,
    (dispatch, { ...props }) => ({
      showUpdateModal(values) {
        dispatch(modalActions.showUpdate({
          id: values.id,
          data: props.header,
        }))
      },
    }),
  ),
)(ExtendedTables);
