import React from "react";
import { connect } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";
import * as actions from "../../actions/modal";
import * as selectors from "../../reducers";
import Table from '../General/Table';

const header = [
  {
    text: 'Pregunta',
    name: 'question',
    type: 'string',
  },
  {
    text: 'opcion 1',
    name: 'opt0',
    type: 'string',
  },
  {
    text: 'opcion 2',
    name: 'opt1',
    type: 'string',
  },
  {
    text: 'opcion 3',
    name: 'opt2',
    type: 'string',
  },
  {
    text: 'opcion 4',
    name: 'opt3',
    type: 'string',
  },
  {
    text: 'respuesta',
    name: 'answer',
    type: 'string',
  },
];

function Modal({
  type,
  hideModal,
  row: {
    data
  },
}) {
  return (
    <>
    {
      type === "activityModal" && (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px", width: "1000px" }}
          cancelBtnBsStyle="success"
          btnSize=""
          onConfirm={() => hideModal()}
        >
          <Table
            title="Preguntas"
            header={header}
            data={data}
          />
        </ReactBSAlert>
      )
    }
    </>
  )
}

export default connect(
  (state) => ({
      type: selectors.getModalType(state), 
      row: selectors.getModalRowData(state),
    }
  ),
  (dispatch) => ({
    hideModal() {
      dispatch(actions.hideModal());
    }
  }),
)(Modal);
