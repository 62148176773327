import { combineReducers } from 'redux';
import { reducer as reducerForm } from 'redux-form';

import login, * as fromLogin from './login';
import modal, * as fromModal from './modal';
import newReducer, * as fromNew from './new';
import category, * as fromCategory from './category';
import benefit, * as fromBenefit from './benefit';
import prize, * as fromPrize from './prize';
import user, * as fromUser from './user';
import activity, * as fromActivity from './activity';
import branch, * as fromBranch from './branch';
import chain, * as fromChain from './chain';
import promotion, * as fromPromotion from './promotion';
import product, * as fromProduct from './product';
import exchangePrize, * as fromExchangesPrizes from './exchangePrize';
import exchange, * as fromExchanges from './exchange';
import level, * as fromLevel from './level';
import observer, * as fromObservers from './observer';
import activityHistory, * as fromActivityHistory from './activityHistory';

export default combineReducers({
  login,
  modal,
  newReducer,
  category,
  benefit,
  prize,
  user,
  activity,
  branch,
  chain,
  form: reducerForm,
  promotion,
  product,
  exchange,
  exchangePrize,
  level,
  observer,
  activityHistory,
});

//login
export const isUserAuthenticated = (state) => fromLogin.isUserAuthenticated(state.login);
export const getUserToken = (state) => fromLogin.getUserToken(state.login);

//modal
export const getModalType = (state) => fromModal.getModalType(state.modal);
export const getModalMessage = (state) => fromModal.getModalMessage(state.modal);
export const getModalTitle = (state) => fromModal.getModalTitle(state.modal);
export const getModalRowData = (state) => fromModal.getModalRowData(state.modal);

//new
export const getAllNews = (state) => fromNew.getAllNews(state.newReducer);

//category
export const getAllCategories = (state) => fromCategory.getAllCategories(state.category);

//benefit
export const getAllBenefits = (state) => fromBenefit.getAllBenefits(state.benefit);

//Level
export const getAllLevels = (state) => fromLevel.getAllLevels(state.level);

//user
export const getAllUsers = (state) => fromUser.getAllUsers(state.user);
export const getONEUsers = (state) => fromUser.getONEUsers(state.user);

//activity
export const getAllActivities = (state) => fromActivity.getAllActivities(state.activity);

//activity
export const getActivityHistory = (state) => fromActivityHistory.getActivityHistory(state.activityHistory);

//branch
export const getAllBranchs = (state) => fromBranch.getAllBranchs(state.branch);

//chain
export const getAllChains= (state) => fromChain.getAllChains(state.chain);

//promotion
export const getAllPromotions= (state) => fromPromotion.getAllPromotions(state.promotion);

//product
export const getAllProducts= (state) => fromProduct.getAllProducts(state.product);

//Prize
export const getAllPrizes = (state) => fromPrize.getAllPrizes(state.prize);

//exchange
export const getAllExchanges= (state) => fromExchanges.getAllExchanges(state.exchange);

//exchangePrize
export const getAllExchangesPrizes= (state) => fromExchangesPrizes.getAllExchangesPrizes(state.exchangePrize);

//observer
export const getAllObservers= (state) => fromObservers.getAllObservers(state.observer);