import * as types from '../types/exchange';

export const fetchAllExchanges = ({
  fromDate,
  toDate,
}) => ({
  type: types.ALL_EXCHANGES_FETCHED,
  payload: {
    fromDate,
    toDate,
  },
});

export const fetchAllExchangesConfirm = ({
  exchanges,
}) => ({
  type: types.ALL_EXCHANGES_FETCHED_SUCCEEDED,
  payload: {
    exchanges,
  },
});

export const fetchAllExchangesDecline = ({
  message,
}) => ({
  type: types.ALL_EXCHANGES_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const downloadAllExchanges = ({
  fromDate,
  toDate,
}) => ({
  type: types.ALL_EXCHANGES_DOWNLOADED,
  payload: {
    fromDate,
    toDate,
  },
});

export const downloadAllExchangesConfirm = ({}) => ({
  type: types.ALL_EXCHANGES_DOWNLOADED_SUCCEEDED,
  payload: {},
});

export const downloadAllExchangesDecline = ({
  message,
}) => ({
  type: types.ALL_EXCHANGES_DOWNLOADED_FAILED,
  payload: {
    message,
  },
});

export const createExchange = ({
  path_to_picture,
  name,
  link_to_exchanges,
}) => ({
  type: types.EXCHANGE_CREATED,
  payload: {
    path_to_picture,
    name,
    link_to_exchanges,
  },
});

export const createExchangeConfirm = () => ({
  type: types.EXCHANGE_CREATED_SUCCEEDED,
  payload: {},
});

export const createExchangeDecline = ({
  message,
}) => ({
  type: types.EXCHANGE_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteExchange = ({
  id,
}) => ({
  type: types.EXCHANGE_DELETED,
  payload: {
    id,
  },
});

export const deleteExchangeConfirm = ({
  data,
}) => ({
  type: types.EXCHANGE_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteExchangeDecline = ({
  message,
}) => ({
  type: types.EXCHANGE_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateExchange = ({
  id,
  path_to_picture,
  name,
  link_to_exchanges,
}) => ({
  type: types.EXCHANGE_UPDATED,
  payload: {
    id,
    path_to_picture,
    name,
    link_to_exchanges,
  },
});

export const updateExchangeConfirm = ({
  data,
}) => ({
  type: types.EXCHANGE_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateExchangeDecline = ({
  message,
}) => ({
  type: types.EXCHANGE_UPDATED_FAILED,
  payload: {
    message,
  },
});
