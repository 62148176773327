export const ALL_USERS_FETCHED = 'ALL_USERS_FETCHED';
export const ALL_USERS_FETCHED_SUCCEEDED = 'ALL_USERS_FETCHED_SUCCEEDED';
export const ALL_USERS_FETCHED_FAILED = 'ALL_USERS_FETCHED_FAILED';

export const ONE_USER_FETCHED = 'ONE_USER_FETCHED';
export const ONE_USER_FETCHED_SUCCEEDED = 'ONE_USER_FETCHED_SUCCEEDED';
export const ONE_USER_FETCHED_FAILED = 'ONE_USER_FETCHED_FAILED';

export const ALL_USERS_DOWNLOADED = 'ALL_USERS_DOWNLOADED';
export const ALL_USERS_DOWNLOADED_SUCCEEDED = 'ALL_USERS_DOWNLOADED_SUCCEEDED';
export const ALL_USERS_DOWNLOADED_FAILED = 'ALL_USERS_DOWNLOADED_FAILED';

export const USER_CONFIRMED = 'USER_CONFIRMED';
export const USER_CONFIRMED_SUCCEEDED = 'USER_CONFIRMED_SUCCEEDED';
export const USER_CONFIRMED_FAILED = 'USER_CONFIRMED_FAILED';

export const USER_DELETED = 'USER_DELETED';
export const USER_DELETED_SUCCEEDED = 'USER_DELETED_SUCCEEDED';
export const USER_DELETED_FAILED = 'USER_DELETED_FAILED';

export const USER_UPDATED = 'USER_UPDATED';
export const USER_UPDATED_SUCCEEDED = 'USER_UPDATED_SUCCEEDED';
export const USER_UPDATED_FAILED = 'USER_UPDATED_FAILED';
