import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm  } from 'redux-form';
import { compose } from 'recompose';
import Card from "../General/Card/Card.js";
import CardBody from "../General/Card/CardBody.js";
import Button from '../General/Button';
import FormInput from "../General/FormInput";
import Modal from '../General/Modal';
import * as loginActions from '../../actions/login';
import styles from './login.module.css';

const Login = ({
  doLogin,
  handleSubmit,
}) => (
  <div className={styles.login}>
    <Modal />
    <div className={styles.container}>
    <Card profile>
      <CardBody profile>
        <form onSubmit={handleSubmit(doLogin.bind(this))}>
          <Field
            component={FormInput}
            type="text"
            name="dpi"
            placeholder="dpi"
          />
          <Field
            component={FormInput}
            type="password"
            name="password"
            placeholder="password"
          />
          <Button
            color="danger"
            type="submit"
          >
            Ingresar
          </Button>
        </form>
      </CardBody>
    </Card>
    </div>
  </div >
);

export default compose(
  reduxForm({
    form: 'login'
  }),
  connect(
    undefined,
    (dispatch) => ({
      doLogin(values) {
        dispatch(loginActions.userLogin({
          dpi: values.dpi,
          password: values.password,
        }));
      },
    }),
  )
)(Login);