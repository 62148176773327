import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import * as types from '../types/login';
import * as actions from '../actions/login';
import * as api from '../apis/login';
import { getUserToken } from '../reducers';
import * as modalActions from '../actions/modal';

function* doLogin(action) {
  const {
    payload: {
      dpi,
      password,
    },
  } = action;
  try {
    const {data} = yield call(
      api.doLogin,
      dpi,
      password,
    );
    yield put(actions.userLoginSuccess({
      id: data.id,
      name: data.name,
      username: data.username,
      lastName: data.last_name,
      stations: data.stations,
      token: data.token,
      permissions: data.permissions,
    }));
  } catch (error) {
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: error.message,
    }))
    yield put(actions.userLoginFail({
      message: error.message,
    }));
  }
}


function* Dologout() {
  const token = yield select(getUserToken);
  try {
    yield call(
      api.doLogoff,
      token,
    );  

    yield put(actions.userLogoutSuccess());
  } catch (error) {
    yield put(actions.userLogoutSuccess());
    yield put(actions.userLogoutFail({
      message: error.message,
    }));
  }
}

function* summaryFetcher(action) {
  const token = yield select(getUserToken);
  //const stations_selected = yield select(getStations_Selected);
  const stations_selected = undefined;
  const {
    payload:{
      dateI
    }
  } = action;
  try {
    const {response, logout} = yield call(
      api.fetchSummary,
      token,
      stations_selected,
      dateI,
    );  
    if (logout) {
      yield put(actions.userLogout())
    } else {
      yield put(actions.fetchSummarySuccess({
        incomes: response.incomes,
        outcomes: response.outcomes,
      }));
    }
  } catch (error) {
    yield put(actions.fetchSummaryFail({
      message: error.message,
    }));
  }
}



function* LoginSaga() {
  yield takeLatest(
    types.USER_LOGGED,
    doLogin,
  );
  yield takeLatest(
    types.USER_UNLOGGED,
    Dologout,
  );
  yield takeLatest(
    types.SUMMARY_REQUESTED,
    summaryFetcher,
  );
}

export default LoginSaga;

