import * as types from '../types/promotion';

export const fetchAllPromotions = () => ({
  type: types.ALL_PROMOTIONS_FETCHED,
  payload: {},
});

export const fetchAllPromotionsConfirm = ({
  promotions,
}) => ({
  type: types.ALL_PROMOTIONS_FETCHED_SUCCEEDED,
  payload: {
    promotions,
  },
});

export const fetchAllPromotionsDecline = ({
  message,
}) => ({
  type: types.ALL_PROMOTIONS_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const createPromotion = ({
  picture,
  dpis,
  active_time_in_hours,
  message,
  title,
  date_to_expire,
  date,
  time
}) => ({
  type: types.PROMOTION_CREATED,
  payload: {
    picture,
    dpis,
    active_time_in_hours,
    message,
    title,
    date_to_expire,
    date,
    time
  },
});

export const createPromotionConfirm = () => ({
  type: types.PROMOTION_CREATED_SUCCEEDED,
  payload: {},
});

export const createPromotionDecline = ({
  message,
}) => ({
  type: types.PROMOTION_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deletePromotion = ({
  id,
}) => ({
  type: types.PROMOTION_DELETED,
  payload: {
    id,
  },
});

export const deletePromotionConfirm = ({
  data,
}) => ({
  type: types.PROMOTION_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deletePromotionDecline = ({
  message,
}) => ({
  type: types.PROMOTION_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updatePromotion = ({
  id,
  path_to_picture,
  message,
  title,
  date_to_expire,
}) => ({
  type: types.PROMOTION_UPDATED,
  payload: {
    id,
    path_to_picture,
    message,
    title,
    date_to_expire,
  },
});

export const updatePromotionConfirm = ({
  data,
}) => ({
  type: types.PROMOTION_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updatePromotionDecline = ({
  message,
}) => ({
  type: types.PROMOTION_UPDATED_FAILED,
  payload: {
    message,
  },
});
