export const USER_LOGGED = 'USER_LOGGED';
export const USER_LOGGED_SUCCEDED = 'USER_LOGGED_SUCCEDED';
export const USER_LOGGED_FAILED = 'USER_LOGGED_FAILED';

export const USER_UNLOGGED = 'USER_UNLOGGED';
export const USER_UNLOGGED_SUCCEDED = 'USER_UNLOGGED_SUCCEDED';
export const USER_UNLOGGED_FAILED = 'USER_UNLOGGED_FAILED';

export const SUMMARY_REQUESTED = 'SUMMARY_REQUESTED'; 
export const SUMMARY_REQUESTED_SUCCEDED = 'SUMMARY_REQUESTED_SUCCEDED'; 
export const SUMMARY_REQUESTED_FAILED = 'SUMMARY_REQUESTED_FAILED'; 

export const STATION_SELECTED = 'STATION_SELECTED';