import * as types from '../types/product';

export const fetchAllProducts = () => ({
  type: types.ALL_PRODUCTS_FETCHED,
  payload: {},
});

export const fetchAllProductsConfirm = ({
  products,
}) => ({
  type: types.ALL_PRODUCTS_FETCHED_SUCCEEDED,
  payload: {
    products,
  },
});

export const fetchAllProductsDecline = ({
  message,
}) => ({
  type: types.ALL_PRODUCTS_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const createProduct = ({
  path_to_picture,
  name,
  coins,
  franchise_ignore
}) => ({
  type: types.PRODUCT_CREATED,
  payload: {
    path_to_picture,
    name,
    coins,
    franchise_ignore
  },
});

export const createProductConfirm = () => ({
  type: types.PRODUCT_CREATED_SUCCEEDED,
  payload: {},
});

export const createProductDecline = ({
  message,
}) => ({
  type: types.PRODUCT_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteProduct = ({
  id,
}) => ({
  type: types.PRODUCT_DELETED,
  payload: {
    id,
  },
});

export const deleteProductConfirm = ({
  data,
}) => ({
  type: types.PRODUCT_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteProductDecline = ({
  message,
}) => ({
  type: types.PRODUCT_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateProduct = ({
  id,
  path_to_picture,
  name,
  coins,
  franchise_ignore,
}) => ({
  type: types.PRODUCT_UPDATED,
  payload: {
    id,
    path_to_picture,
    name,
    coins,
    franchise_ignore,
  },
});

export const updateProductConfirm = ({
  data,
}) => ({
  type: types.PRODUCT_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateProductDecline = ({
  message,
}) => ({
  type: types.PRODUCT_UPDATED_FAILED,
  payload: {
    message,
  },
});
