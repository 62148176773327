import React from "react";
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import ReactTable from "react-table";
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import FormInput from '../FormInput';
import FormFile from '../FormFile';
import FormSelect from '../FormSelect';
import FormOptions from '../FormOptions';
import FormDate from '../FormDate';
import FormTime from '../FormTime';
import Modal from '../Modal';
import * as modalActions from '../../../actions/modal';
import * as selectors from '../../../reducers';

class ExtendedTables extends React.Component {
  onSubmit(values) {
    const {
      createAction
    } = this.props;
    createAction(values);
  }

  render() {
    const {
      data,
      header,
      title,
      createAction,
      deleteAction,
      updateAction,
      showUpdateModal,
      showModal,
      client,
      updateActionUser,
      extraAction,
      handleSubmit,
    } = this.props;
    return (
      <>
        {
          updateAction ? ( 
              <Modal updateAction={updateAction.bind(this)}/>
            ) : (
              <Modal/>
          )
        }
        {
          updateActionUser ? ( 
              <Modal updateAction={updateActionUser.bind(this)}/>
            ) : (
              <Modal/>
          )
        }
        {
          createAction && (
            <Card>
              <CardBody>
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                  <CardTitle>
                    Agregar {title}
                  </CardTitle>
                  <Row>
                    <Col sm="10">
                      <Row>
                        {
                          header.map((column)=> (
                            <Col sm="3" style={{marginTop: '10px'}}>
                                {
                                  column.type === "file" && (
                                    <Field
                                      name={column.name}
                                      component={FormFile}
                                      placeholder={column.text}
                                    />
                                  )
                                } 
                                {
                                  column.type === "select" && (
                                    <Field
                                      name={column.name}
                                      component={FormSelect}
                                      placeholder={column.text}
                                      options={column.options}
                                    />
                                  )
                                }  
                                {
                                  column.type === "string" && (
                                    <Field
                                      name={column.name}
                                      component={FormInput}
                                      type="text"
                                      placeholder={column.text}
                                    />
                                  )
                                }
                                {
                                  column.type === "options" && (
                                    <Field
                                      name={column.name}
                                      component={FormOptions}
                                      action={() => column.showAction()}
                                    />
                                  )
                                }
                                {
                                  column.type === 'date' && (
                                    <Field
                                      name={column.name}
                                      component={FormDate}
                                      placeholder={column.text}
                                    />
                                  )
                                }
                                {
                                  column.type === 'time' && (
                                    <Field
                                      name={column.name}
                                      component={FormTime}
                                      placeholder={column.text}
                                    />
                                  )
                                }
                                {
                                  column.type === 'special' && (
                                    <Field
                                      name={column.name}
                                      component={column.component}
                                      placeholder={column.text}
                                    />
                                  )
                                }
                            </Col>
                          ))
                        }
                      </Row>
                    </Col>
                    <Col sm="2">
                      <Button 
                        color="info"
                        type="submit"
                      >
                        Agregar
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          )
        }
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <ReactTable 
                  data={data.map((data, key) => ({
                    ...data,
                    actions: (
                      <div className="actions-right">
                        {
                          updateActionUser && (
                            <>
                              <Button
                                className="btn-link btn-icon"
                                color="success"
                                id="tooltip324367706"
                                size="sm"
                                onClick={() => {
                                  updateActionUser(data.id);
                                  setTimeout(() => showModal(data.id, client.OneUser), 500);
                              }}
                              >
                                <i className="tim-icons icon-pencil" />
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip324367706"
                              >
                                Actualizar
                              </UncontrolledTooltip>
                            </>
                          ) 
                        }
                        {
                          updateAction && (
                            <>
                              <Button
                                className="btn-link btn-icon"
                                color="success"
                                id="tooltip324367706"
                                size="sm"
                                onClick={() => (showModal(data.id, data))}
                              >
                                <i className="tim-icons icon-pencil" />
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip324367706"
                              >
                                Actualizar
                              </UncontrolledTooltip>
                            </>
                          ) 
                        }
                        {
                          deleteAction && (
                            <>
                              <Button
                                className="btn-link"
                                color="danger"
                                id="tooltip974171201"
                                size="sm"
                                onClick={() => deleteAction(data.id)}
                              >
                                <i className="tim-icons icon-simple-remove" />
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip974171201"
                              >
                                Eliminar
                              </UncontrolledTooltip>
                            </>
                          )
                        }
                      </div>
                    )
                  }))}
                  resizable={false}
                  columns={[
                    ...header.map(column => ({
                      Header: column.text,
                      accessor: column.name,
                    })),
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={20}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default compose(
  reduxForm({
    form: "tableForm"
  }),
  connect(
    (state) => ({
      client: selectors.getONEUsers(state),
    }),
    (dispatch, {...props}) => ({
      showUpdateModal(values) {
        dispatch(modalActions.showUpdate({
          id: values.id,
          data: props.header,
        }))
      },
    }),
  ),
)(ExtendedTables);
