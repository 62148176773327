import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
//import Card from "../General/Card/Card";
//import CardAvatar from "../General/Card/CardAvatar.js";
//import CardBody from "../General/Card/CardBody.js";
//import Button from '../General/Button';
import * as actions from "../../actions/modal";
import * as selectors from "../../reducers";
import FormInput from '../General/FormInput';
import FormSelect from '../General/FormSelect';


const FieldPregunta = ({
  fields,
  /**
  meta: {
    touched,
    error,
    submitFailed,
  },
   */
}) => (
  <> 
    {
      fields.map((member, index) => (
        <Row>
          <Col  md="3">
            <FormGroup>
              <label>Pregunta #{index + 1}</label>
              <Field
                name={`${member}.pregunta`}
                component={FormInput}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col  md="2">
            <FormGroup>
              <label>Opcion #1</label>
              <Field
                name={`${member}.opcion1`}
                component={FormInput}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col  md="2">
            <FormGroup>
              <label>Opcion #2</label>
              <Field
                name={`${member}.opcion2`}
                component={FormInput}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col  md="2">
            <FormGroup>
              <label>Opcion #3</label>
              <Field
                name={`${member}.opcion3`}
                component={FormInput}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col  md="2">
            <FormGroup>
              <label>Opcion #4</label>
              <Field
                name={`${member}.opcion4`}
                component={FormInput}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col  md="1">
            <FormGroup>
              <label>Respuesta</label>
              <Field
                name={`${member}.respuesta`}
                component={FormSelect}
                type="select"
                options={[
                  {
                    value: 1,
                    label: 1,
                  },
                  {
                    value: 2,
                    label: 2,
                  },
                  {
                    value: 3,
                    label: 3,
                  },
                  {
                    value: 4,
                    label: 4,
                  },
                ]}
              />
            </FormGroup>
          </Col>
        </Row>
      )) 
    }
      <CardFooter className="text-center">
      <Button color="danger" onClick={()=>fields.remove(fields.length - 1)}>
        Eliminar Pregunta
      </Button>
      {
        fields.length < 4 && (
          <Button onClick={()=>fields.push({})}>
            Agregar Pregunta
          </Button>
        )
      }
    </CardFooter>
  </>

);

class UserModalCore extends Component {
  constructor(props){
    super(props);
    this.state = {
      visible: false,
    }
  }

  onSubmit(values) {
    const {
      input: { onChange },
    } = this.props;
    onChange(values.preguntas);
    this.setState({visible: false})
  }

  render() {
    const {
      handleSubmit,
      preguntas,
    } = this.props;
    const {
      visible,
    } = this.state;
    return (
    <>
    {
      visible && (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px", width: "1000px" }}
          title="Ingresa el Contenido"
          onConfirm={handleSubmit(this.onSubmit.bind(this))}
          onCancel={() => this.setState({visible: false})}
          cancelBtnBsStyle="success"
          btnSize=""
          showCancel
        >
          <Card>
            <CardBody>
              <Form>
                <FieldArray
                  name="preguntas" //data[column.name]
                  component={FieldPregunta}
                />
              </Form>
            </CardBody>
          </Card>
        </ReactBSAlert>
      )
     }
     <Button 
        color="info"
        onClick={() => this.setState({ visible:true })}
      >
        Preguntas({preguntas ? preguntas.length : 0})
      </Button>
    </>
    )
  }
}

const Modal = reduxForm({
  form: "ActivitesModal",
})(UserModalCore);

export default connect(
  (state) => ({
    preguntas: formValueSelector("ActivitesModal")(state, "preguntas"),
  })
)(Modal);
