import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import FormDate from '../General/FormDate';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import * as selectors from '../../reducers';
import * as actions from '../../actions/observer';

const header = [
  {
    text: 'Usuario',
    name: 'user',
    type: 'string',
  },
  {
    text: 'Dia y Hora',
    name: 'timestamp',
    type: 'string',
  },
  {
    text: 'Duracion',
    name: 'duration',
    type: 'string',
  },
  {
    text: 'Noticia',
    name: 'news',
    type: 'string',
  },
  
];

class Observers extends Component {
  constructor(props){
    super(props)
    this.state = {
      fromDate: undefined,
      toDate: undefined,
    }
  }
  componentDidMount(){
    const {
      fetchObservers,
    } = this.props;
    fetchObservers({});
  }

  changeDate(date, value){
    this.setState({
      [date]: value
    })
  }

  render() {
    
    const {
      observers,      
      fetchObservers,
      downloadObservers
    } = this.props;
    const {
      fromDate,
      toDate,
    } = this.state;
    return (
      <>
        <Card>
            <CardBody>
              <CardTitle>
                Lista de tiempos
              </CardTitle>
              <Row>
                <Col sm="2">
                  <FormDate 
                    placeholder="desde"
                    value={fromDate}
                    input={{
                      onChange: (value) => this.changeDate("fromDate", value)
                    }}
                  />
                </Col>
                <Col sm="2">
                  <FormDate 
                    placeholder="hasta"
                    value={toDate}
                    input={{
                      onChange: (value) => this.changeDate("toDate", value)
                    }}
                  />
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                    onClick={() => downloadObservers({fromDate, toDate})}
                  >
                    Descargar
                  </Button>
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                    onClick={() => fetchObservers({fromDate, toDate})}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        <Table
          title="Observador"
          header={header}
          data={observers}
          
        />
      </>
  );
  }
}

export default connect(
  (state) => ({
    observers: selectors.getAllObservers(state),
  }),
  (dispatch) => ({
    fetchObservers(values) {
      dispatch(actions.fetchAllObservers({
        fromDate: values.fromDate,
        toDate: values.toDate,
      }))
    },
    downloadObservers(values) {
      dispatch(actions.downloadAllObservers({
        fromDate: values.fromDate,
        toDate: values.toDate,
      }))
    },
  })
)(Observers);
