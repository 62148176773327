import { combineReducers } from 'redux';
import * as types from '../types/new';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_NEWS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          news,
        },
      } = action;
      const newState = {}
      for (let i in news) {
        newState[news[i].id] = {
          ...news[i],
          franchise: news[i].franchise ? news[i].franchise.name : "TODOS",
        }
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_NEWS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          news,
        },
      } = action;
      const newState = Object.values(news).map((noticia) => noticia.id);
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getNewById = (state, id) => state.byId[id] || undefined; 
export const getAllNews = (state) => state.order.map((id) => getNewById(state, id));

