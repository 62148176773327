
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import * as selectors from '../../reducers';
import * as actions from '../../actions/prize';
import * as categoryActions from '../../actions/category';
import * as modalActions from '../../actions/modal';

class Prizes extends Component {
  componentDidMount(){
    const {
      fetchAllPrizes,
      fetchAlLCategories,
    } = this.props;
    fetchAllPrizes();
    fetchAlLCategories();
  }

  render() {
    const {
      createPrize,
      deletePrize,
      updatePrize,
      prizes,
      categories,
      showPrizeModal,
    } = this.props;
    const header = [
      {
        text: 'Imagen',
        name: 'picture',
        type: 'file',
      },
      {
        text: 'Nombre',
        name: 'name',
        type: 'string',
      },
      {
        text: 'Costo',
        name: 'price',
        type: 'string',
      },
      {
        text: 'Categoria',
        name: 'categor', //truena con category
        type: 'select',
        options: categories.map((category) => ({
            value:  category.id,
            label: category.name,
          })
        ),
      },
      {
        text: 'Tipo',
        name: 'type',
        type: 'select',
        options: [
          {
            value: 'ONE',
            label: 'Una vez',
          },
          {
            value: 'MULTI',
            label: 'Varias',
          },
        ],
      },
    ];
    return (
      <Table
        title="Premio"
        header={header}
        data={prizes}
        createAction={createPrize.bind(this)}
        deleteAction={deletePrize.bind(this)}
        showModal={showPrizeModal.bind(this)}
        updateAction={updatePrize.bind(this)}
      />
    );
  }
}

export default connect(
  (state) => ({
    prizes: selectors.getAllPrizes(state),
    categories: selectors.getAllCategories(state),
  }),
  (dispatch) => ({
    fetchAllPrizes() {
      dispatch(actions.fetchAllPrizes());
    },
    fetchAlLCategories() {
      dispatch(categoryActions.fetchAllPrizesCategories());
    },
    createPrize(values) {
      dispatch(actions.createPrize({
        picture: values.picture,
        name: values.name,
        price: values.price,
        category: values.categor,
        type: values.type,
      }));
    },
    deletePrize(id) {
      dispatch(actions.deletePrize({
        id,
      }))
    },
    showPrizeModalCore(id, data, categories,) {
      dispatch(modalActions.showUpdate({
        id: id,
        data: [
          {
            label: 'Image',
            name: 'picture',
            text: data.path_to_picture,
            type: 'file'
          },
          {
            label: 'Nombre',
            name: 'name',
            text: data.name,
            type: 'string'
          },
          {
            label: 'Costo',
            text: data.price,
            name: 'price',
            type: 'string',
          },
          {
            label: 'Categoria',
            text: data.categor,
            name: 'categor', //truena con category
            type: 'select',
            options: categories.map((category) => ({
                value:  category.id,
                label: category.name,
              })
            ),
          },
          {
            label: 'Tipo',
            text: data.type,
            name: 'type',
            type: 'select',
            options: [
              {
                value: 'ONE',
                label: 'Una vez',
              },
              {
                value: 'MULTI',
                label: 'Varias',
              },
            ],
          },
        ],
      }))
    },
    updatePrize(values){ 
      dispatch(actions.updatePrize({
        id: values.id,
        picture: values.picture,
        name: values.name,
        price: values.price,
        category: values.categor,
        type: values.type,
      }))
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    showPrizeModal(id, data) {
      dispatchProps.showPrizeModalCore(id, data, stateProps.categories);
    },
  })
)(Prizes);
