import { combineReducers } from 'redux';
import * as types from '../types/benefit';

const places = {
  'PHARMACY' : 'Farmacia',
  'PLACE' : 'Establecimiento',
  'NONE' : '',
}

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_BENEFITS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          benefits,
        },
      } = action;
      const benefitState = {}
      for (let i in benefits) {
        const benefit = benefits[i];
        benefitState[benefit.id] = {
          ...benefit,
          categor: benefit.category.name,
          exchange_place: places[benefit.exchange_place],
        }
      }
      return benefitState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_BENEFITS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          benefits,
        },
      } = action;
      const benefitState = Object.values(benefits).map((noticia) => noticia.id);
      return benefitState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getBenefitById = (state, id) => state.byId[id] || undefined; 
export const getAllBenefits = (state) => state.order.map((id) => getBenefitById(state, id));

