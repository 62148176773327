import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import FormDate from '../General/FormDate';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import * as selectors from '../../reducers';
import * as actions from '../../actions/exchangePrize';

const header = [
  {
    text: 'Imagen',
    name: 'picture',
    type: 'file',
  },
  {
    text: 'Cadena',
    name: 'franchise',
    type: 'string',
  },
  {
    text: 'Sucursal',
    name: 'branch',
    type: 'string',
  },
  {
    text: 'Premio',
    name: 'prize',
    type: 'string',
  },
  {
    text: 'Telefono',
    name: 'phone',
    type: 'string',
  },
  {
    text: 'Dependiente',
    name: 'dependiente',
    type: 'string',
  },
  {
    text: 'Fecha',
    name: 'exchange_at',
    type: 'string',
  },
];

class ExchangesPrizes extends Component {
  constructor(props){
    super(props)
    this.state = {
      fromDate: undefined,
      toDate: undefined,
    }
  }
  componentDidMount(){
    const {
      fetchExchangesPrizes,
    } = this.props;
    fetchExchangesPrizes({});
  }

  changeDate(date, value){
    this.setState({
      [date]: value
    })
  }

  render() {
    const {
      exchangesPrizes,
      fetchExchangesPrizes,
      downloadExchangesPrizes
    } = this.props;
    const {
      fromDate,
      toDate,
    } = this.state;
    return (
      <>
        <Card>
            <CardBody>
              <CardTitle>
                Lista de pendientes:
              </CardTitle>
              <Row>
                <Col sm="2">
                  <FormDate 
                    placeholder="desde"
                    value={fromDate}
                    input={{
                      onChange: (value) => this.changeDate("fromDate", value)
                    }}
                  />
                </Col>
                <Col sm="2">
                  <FormDate 
                    placeholder="hasta"
                    value={toDate}
                    input={{
                      onChange: (value) => this.changeDate("toDate", value)
                    }}
                  />
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                    onClick={() => downloadExchangesPrizes({fromDate, toDate})}
                  >
                    Descargar
                  </Button>
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                    onClick={() => fetchExchangesPrizes({fromDate, toDate})}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        <Table
          title="Canje de premios"
          header={header}
          data={exchangesPrizes}
        />
      </>
  );
  }
}

export default connect(
  (state) => ({
    exchangesPrizes: selectors.getAllExchangesPrizes(state),
  }),
  (dispatch) => ({
    fetchExchangesPrizes(values) {
      dispatch(actions.fetchAllExchangesPrizes({
        fromDate: values.fromDate,
        toDate: values.toDate,
      }))
    },
    downloadExchangesPrizes(values) {
      dispatch(actions.downloadAllExchangesPrizes({
        fromDate: values.fromDate,
        toDate: values.toDate,
      }))
    },
  })
)(ExchangesPrizes);
