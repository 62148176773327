import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/product';
import * as api from '../apis/product';
import * as actions from '../actions/product';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* productsFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {

    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllProducts,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllProductsConfirm({
        products: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllProductsDecline({
      message: error.message,
    }));
  }
}

function* productsCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      path_to_picture,
      name,
      coins,
      franchise_ignore,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createProduct,
      token,
      path_to_picture,
      name,
      coins,
      franchise_ignore,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createProductConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllProducts());
    }
  } catch (error) {
    yield put(actions.createProductDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* productsEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteProduct,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteProductConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllProducts());
    }
  } catch (error) {
    yield put(actions.deleteProductDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* productsUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      path_to_picture,
      name,
      coins,
      franchise_ignore,
    }
  } = action;
  try {
    if (path_to_picture) {
      yield call(
        api.updateProductPicture,
        token,
        id,
        path_to_picture,
      );
    };
    const { /*response,*/ logout } = yield call(
      api.updateProduct,
      token,
      id,
      name,
      coins,
      franchise_ignore,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteProductConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllProducts());
    }
  } catch (error) {
    yield put(actions.deleteProductDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* ProductsSaga() {
  yield takeLatest(
    types.ALL_PRODUCTS_FETCHED,
    productsFetcher,
  );
  yield takeLatest(
    types.PRODUCT_CREATED,
    productsCreator,
  );
  yield takeLatest(
    types.PRODUCT_DELETED,
    productsEliminator,
  );
  yield takeLatest(
    types.PRODUCT_UPDATED,
    productsUpdator,
  );
}
