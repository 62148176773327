import URL from './routes';

export const fetchAllExchanges = (
  token,
  fromDate,
  toDate,
) => new Promise((resolve, reject) => {
  const params = fromDate && toDate ? `?fromDate=${fromDate.toISOString().slice(0, 10)}&toDate=${toDate.toISOString().slice(0, 10)}` : ''
  fetch(`${URL}/benefit/exchange_award_report/${params}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const downloadAllExchanges = (
  token,
  fromDate,
  toDate,
) => new Promise((resolve, reject) => {
  const params = fromDate && toDate ? `?fromDate=${fromDate.toISOString().slice(0, 10)}&toDate=${toDate.toISOString().slice(0, 10)}` : ''
  fetch(`${URL}/benefit/exchange_award_history/${params}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.blob()
      .then( blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const juntado = `Canjes Beneficios ${fromDate ? fromDate.toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10)} - ${toDate ? toDate.toISOString().slice(0, 10) : new Date().toISOString().slice(0, 10)}.xlsx`
        a.download = juntado
        document.body.appendChild(a); 
        a.click();    
        a.remove(); 
      });
    } else {
      resultado.json().then((res) => resolve({
        response: res,
        error: true,
        logout: resultado.status === 401,
      }));
    }
  }).catch((error) => reject(error));
});

export const createExchange = (
  token,
  path_to_picture,
  name,
  link_to_exchange,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  path_to_picture && data.append('picture', path_to_picture);
  name && data.append('name', name);
  link_to_exchange && data.append('link', link_to_exchange);
  fetch(`${URL}/exchange/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const deleteExchange = (
  token,
  id,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/exchange/${id}/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resolve({
        logout: false,
      });
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateExchange = (
  token,
  id,
  path_to_picture,
  name,
  link_to_exchange,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  path_to_picture && data.append('path_to_picture', path_to_picture);
  name && data.append('name', name);
  link_to_exchange && data.append('link_to_exchange', link_to_exchange);
  fetch(`${URL}/exchange/${id}/`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 
