export const ALL_PROMOTIONS_FETCHED = 'ALL_PROMOTIONS_FETCHED';
export const ALL_PROMOTIONS_FETCHED_SUCCEEDED = 'ALL_PROMOTIONS_FETCHED_SUCCEEDED';
export const ALL_PROMOTIONS_FETCHED_FAILED = 'ALL_PROMOTIONS_FETCHED_FAILED';

export const PROMOTION_CREATED = 'PROMOTION_CREATED';
export const PROMOTION_CREATED_SUCCEEDED = 'PROMOTION_CREATED_SUCCEEDED';
export const PROMOTION_CREATED_FAILED = 'PROMOTION_CREATED_FAILED';

export const PROMOTION_DELETED = 'PROMOTION_DELETED';
export const PROMOTION_DELETED_SUCCEEDED = 'PROMOTION_DELETED_SUCCEEDED';
export const PROMOTION_DELETED_FAILED = 'PROMOTION_DELETED_FAILED';

export const PROMOTION_UPDATED = 'PROMOTION_UPDATED';
export const PROMOTION_UPDATED_SUCCEEDED = 'PROMOTION_UPDATED_SUCCEEDED';
export const PROMOTION_UPDATED_FAILED = 'PROMOTION_UPDATED_FAILED';
