import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/branch';
import * as api from '../apis/branch';
import * as actions from '../actions/branch';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* branchsFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      chainId
    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllBranchs,
      token,
      chainId
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllBranchsConfirm({
        branchs: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllBranchsDecline({
      message: error.message,
    }));
  }
}

function* branchsCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      path_to_picture,
      name,
      link_to_branchs,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createBranch,
      token,
      path_to_picture,
      name,
      link_to_branchs,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createBranchConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllBranchs());
    }
  } catch (error) {
    yield put(actions.createBranchDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* branchsEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteBranch,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteBranchConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllBranchs());
    }
  } catch (error) {
    yield put(actions.deleteBranchDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* branchsUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      path_to_picture,
      name,
      link_to_branchs,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.updateBranch,
      token,
      id,
      path_to_picture,
      name,
      link_to_branchs,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteBranchConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllBranchs());
    }
  } catch (error) {
    yield put(actions.deleteBranchDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* BranchsSaga() {
  yield takeLatest(
    types.ALL_BRANCHS_FETCHED,
    branchsFetcher,
  );
  yield takeLatest(
    types.BRANCH_CREATED,
    branchsCreator,
  );
  yield takeLatest(
    types.BRANCH_DELETED,
    branchsEliminator,
  );
  yield takeLatest(
    types.BRANCH_UPDATED,
    branchsUpdator,
  );
}
