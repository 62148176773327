import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/exchange';
import * as api from '../apis/exchange';
import * as actions from '../actions/exchange';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* exchangesFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      fromDate,
      toDate,
    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllExchanges,
      token,
      fromDate,
      toDate,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllExchangesConfirm({
        exchanges: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllExchangesDecline({
      message: error.message,
    }));
  }
}

function* exchangesDownloader(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      fromDate,
      toDate,
    },
  } = action;
  try {
    const { logout } = yield call(
      api.downloadAllExchanges,
      token,
      fromDate,
      toDate,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.downloadAllExchangesConfirm({
      }));
    }
  } catch (error) {
    yield put(actions.downloadAllExchangesDecline({
      message: error.message,
    }));
  }
}

function* exchangesCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      path_to_picture,
      name,
      link_to_exchanges,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createExchange,
      token,
      path_to_picture,
      name,
      link_to_exchanges,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createExchangeConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllExchanges());
    }
  } catch (error) {
    yield put(actions.createExchangeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* exchangesEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteExchange,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteExchangeConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllExchanges());
    }
  } catch (error) {
    yield put(actions.deleteExchangeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* exchangesUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      path_to_picture,
      name,
      link_to_exchanges,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.updateExchange,
      token,
      id,
      path_to_picture,
      name,
      link_to_exchanges,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteExchangeConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllExchanges());
    }
  } catch (error) {
    yield put(actions.deleteExchangeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* ExchangesSaga() {
  yield takeLatest(
    types.ALL_EXCHANGES_FETCHED,
    exchangesFetcher,
  );
  yield takeLatest(
    types.ALL_EXCHANGES_DOWNLOADED,
    exchangesDownloader,
  );
  yield takeLatest(
    types.EXCHANGE_CREATED,
    exchangesCreator,
  );
  yield takeLatest(
    types.EXCHANGE_DELETED,
    exchangesEliminator,
  );
  yield takeLatest(
    types.EXCHANGE_UPDATED,
    exchangesUpdator,
  );
}
