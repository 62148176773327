import URL from './routes';

export const fetchAllActivities = (
  token,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/game/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const fetchActivityHistory = (
  token,
  activityId,
  fromDate,
  toDate,
) => new Promise((resolve, reject) => {
  const params = fromDate && toDate ? `?activityId=${activityId}&fromDate=${fromDate.toISOString().slice(0, 10)}&toDate=${toDate.toISOString().slice(0, 10)}` : `?activityId=${activityId}`
  fetch(`${URL}/game/get_history_by_game/${params}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const downloadActivityHistory = (
  token,
  activityId,
  fromDate,
  toDate,
) => new Promise((resolve, reject) => {
  const params = fromDate && toDate ? `?activityId=${activityId}&fromDate=${fromDate.toISOString().slice(0, 10)}&toDate=${toDate.toISOString().slice(0, 10)}` : `?activityId=${activityId}`
  fetch(`${URL}/game/get_history_by_game_report/${params}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.blob()
      .then( blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const dateName = fromDate && toDate ? `${fromDate.toISOString().slice(0, 10)} - ${toDate.toISOString().slice(0, 10)}` : ""
        const juntado = `Actividades ${dateName}.xlsx`
        a.download = juntado
        document.body.appendChild(a); 
        a.click();    
        a.remove(); 
      });
      resolve({
        logout: false,
      })
    } else {
      resultado.json().then((res) => resolve({
        response: res,
        error: true,
        logout: resultado.status === 401,
      }));
    }
  }).catch((error) => reject(error));
});

export const createActivity = (
  token,
  name,
  quantity_of_points,
  game_time,
  link,
  limit_date,
  questions,
  franchise,
  max_entrys,
  date,
  time
) => new Promise((resolve, reject) => {
  const data = new FormData();
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  date = formattedDate;
  fetch(`${URL}/game/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      name,
      quantity_of_points,
      game_time,
      link,
      limit_date,
      questions,
      franchise,
      max_entrys,
      date,
      time
    }),
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const deleteActivity = (
  token,
  id,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/game/${id}/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resolve({
        logout: false,
      });
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateActivityImage = (
  token,
  id,
  picture,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  picture && data.append('picture', picture);
  id && data.append('game_id', id);
  fetch(`${URL}/game/upload_picture/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateActivity = (
  token,
  id,
  name,
  link_to_game,
  quantity_of_points,
  game_time,
  limit_date,
  max_entrys
) => new Promise((resolve, reject) => {
  const data = new FormData();
  name && data.append('name', name);
  link_to_game && data.append('link_to_game', link_to_game);
  quantity_of_points && data.append('quantity_of_points', quantity_of_points);
  game_time && data.append('game_time', game_time);
  limit_date && data.append('limit_date', limit_date);
  max_entrys && data.append('max_entrys', max_entrys);
  fetch(`${URL}/game/${id}/`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 
