import { combineReducers } from 'redux';
import * as types from '../types/observer';

/*const places = {
  'PHARMACY' : 'Farmacia',
  'PLACE' : 'Establecimiento',
  'NONE' : '',
}*/

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_OBSERVERS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          observers:{data},
        },
      } = action;
      const observerState = {}
      for (let i in data) {
        observerState[data[i].id] = {
          ...data[i],
        }
      }
      return observerState;
    }
    
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_OBSERVERS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          observers:{data},
        },
      } = action;
      const observerState = Object.values(data).map((noticia) => noticia.id);
      return observerState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getObserverById = (state, id) => state.byId[id] || undefined; 
export const getAllObservers = (state) => state.order.map((id) => getObserverById(state, id));

