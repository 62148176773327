import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken, getProjId } from '../reducers';
import * as types from '../types/benefit';
import * as api from '../apis/benefit';
import * as actions from '../actions/benefit';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* benefitsFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {

    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllBenefits,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllBenefitsConfirm({
        benefits: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllBenefitsDecline({
      message: error.message,
    }));
  }
}

function* levelsFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {

    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllLevels,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllLevelsConfirm({
        levels: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllLevelsDecline({
      message: error.message,
    }));
  }
}

function* benefitsCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      picture,
      name,
      price,
      level,
      category,
      exchange_place,
      type,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createBenefit,
      token,
      picture,
      name,
      price,
      level,
      category,
      exchange_place,
      type,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createBenefitConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllBenefits());
    }
  } catch (error) {
    yield put(actions.createBenefitDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* benefitsEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteBenefit,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteBenefitConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllBenefits());
    }
  } catch (error) {
    yield put(actions.deleteBenefitDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* benefitsUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      picture,
      name,
      price,
      level,
      category,
      exchange_place,
      type,
    }
  } = action;
  try {
    if (picture) {
      yield call(
        api.updateBenefitImage,
        token,
        id,
        picture,
      );
    };
    const { /*response,*/ logout } = yield call(
      api.updateBenefit,
      token,
      id,
      name,
      price,
      level,
      category,
      exchange_place,
      type,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteBenefitConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllBenefits());
    }
  } catch (error) {
    yield put(actions.deleteBenefitDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* BenefitsSaga() {
  yield takeLatest(
    types.ALL_BENEFITS_FETCHED,
    benefitsFetcher,
  );
  yield takeLatest(
    types.ALL_LEVELS_FETCHED,
    levelsFetcher,
  );
  yield takeLatest(
    types.BENEFIT_CREATED,
    benefitsCreator,
  );
  yield takeLatest(
    types.BENEFIT_DELETED,
    benefitsEliminator,
  );
  yield takeLatest(
    types.BENEFIT_UPDATED,
    benefitsUpdator,
  );
}
