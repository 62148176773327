import URL from './routes';

export const fetchAllBenefits = (
  token,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/benefit/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const fetchAllLevels = (
  token,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/level/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const createBenefit = (
  token,
  picture,
  name,
  price,
  level,
  category,
  exchange_place,
  type,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  picture && data.append('picture', picture);
  name && data.append('name', name);
  price && data.append('price', price);
  level && data.append('level_to_get', level);
  category && data.append('category', category);
  exchange_place && data.append('exchange_place', exchange_place);
  type && data.append('type', type);
  fetch(`${URL}/benefit/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const deleteBenefit = (
  token,
  id,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/benefit/${id}/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resolve({
        logout: false,
      });
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateBenefitImage = (
  token,
  id,
  path_to_picture,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  path_to_picture && data.append('picture', path_to_picture);
  id && data.append('benefit_id', id);
  fetch(`${URL}/benefit/upload_picture/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateBenefit = (
  token,
  id,
  name,
  price,
  level,
  category,
  exchange_place,
  type,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  name && data.append('name', name);
  price && data.append('price', price);
  level && data.append('level_to_get', level);
  category && data.append('category', category);
  exchange_place && data.append('exchange_place', exchange_place);
  type && data.append('type', type);
  fetch(`${URL}/benefit/${id}/`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 
