import React from 'react';
import PropTypes from 'prop-types';
import ReactDatetime from "react-datetime";

const FormDate = ({
  input: { onChange },
  placeholder,
  value,
}) => (
  <ReactDatetime
    inputProps={{
      placeholder,
    }}
    value={value}
    onChange={(target) => onChange(target._d)}
    timeFormat={false}
  />
);

FormDate.propTypes = {
  // cambiar a un objeto
  input: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default FormDate;
