import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import * as selectors from '../../reducers';
import * as actions from '../../actions/chain';
import * as modalActions from '../../actions/modal';

const header = [
  {
    text: 'Imagen',
    name: 'path_to_picture',
    type: 'file',
  },
  {
    text: 'Nombre',
    name: 'name',
    type: 'string',
  },
];

class Chaines extends Component {
  componentDidMount(){
    const {
      fetchChaines,
    } = this.props;
    fetchChaines();
  }

  render() {
    const {
      branches,
      showChainModal,
      updateChain,
    } = this.props;
    return (
      <Table
        title="Productos"
        header={header}
        data={branches}
        showModal={showChainModal.bind(this)}
        updateAction={updateChain.bind(this)}
        //deleteAction={deleteChain.bind(this)}
      />
  );
  }
}

export default connect(
  (state) => ({
    branches: selectors.getAllChains(state),
  }),
  (dispatch) => ({
    fetchChaines() {
      dispatch(actions.fetchAllChains())
    },
    showChainModal(id, data) {
      dispatch(modalActions.showUpdate({
        id: id,
        data: [
          {
            label: 'Image',
            name: 'path_to_picture',
            text: data.path_to_picture,
            type: 'file'
          },
          /**
          {
            label: 'Nombre',
            name: 'name',
            text: data.name,
            type: 'string'
          },
           */
        ],
      }))
    },
    updateChain(values) { 
      dispatch(actions.updateChain({
        id: values.id,
        path_to_picture: values.path_to_picture,
      }))
    },
  })
)(Chaines);
