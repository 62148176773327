import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'reactstrap';

const FormInput = ({
  input: { onChange },
  placeholder,
  type,
  value,
  disabled,
}) => (
  <Input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={({ target }) => onChange(target.value)}
    autoFocus={false}
    formNoValidate
    disabled={disabled}
  />
);

FormInput.propTypes = {
  input: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default FormInput;
