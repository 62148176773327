import { combineReducers } from 'redux';
import * as types from '../types/category';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_PRIZES_CATEGORIES_FETCHED_SUCCEEDED:
    case types.ALL_BENEFITS_CATEGORIES_FETCHED_SUCCEEDED:
    case types.ALL_CATEGORIES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          categories,
        },
      } = action;
      const newState = {}
      for (let i in categories) {
        newState[categories[i].id] = {
          ...categories[i],
        }
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_PRIZES_CATEGORIES_FETCHED_SUCCEEDED:
    case types.ALL_BENEFITS_CATEGORIES_FETCHED_SUCCEEDED:
    case types.ALL_CATEGORIES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          categories,
        },
      } = action;
      const newState = Object.values(categories).map((noticia) => noticia.id);
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getCategoryById = (state, id) => state.byId[id] || undefined; 
export const getAllCategories = (state) => state.order.map((id) => getCategoryById(state, id));

