import URL from './routes';

export const fetchAllProducts = (
  token,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/product/get_admin_products/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const createProduct = (
  token,
  path_to_picture,
  name,
  coins,
  franchise_ignore,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  path_to_picture && data.append('picture', path_to_picture);
  name && data.append('name', name);
  coins && data.append('coins', coins);
  franchise_ignore && data.append('franchise_ignore', JSON.stringify(franchise_ignore));
  fetch(`${URL}/product/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const deleteProduct = (
  token,
  id,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/product/${id}/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resolve({
        logout: false,
      });
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateProductPicture = (
  token,
  id,
  path_to_picture,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  path_to_picture && data.append('picture', path_to_picture);
  id && data.append('product_id', id);
  fetch(`${URL}/product/upload_picture/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateProduct = (
  token,
  id,
  name,
  coins,
  franchise_ignore,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  name && data.append('name', name);
  coins && data.append('coins', coins);
  franchise_ignore && franchise_ignore.forEach(
    franchiseId => data.append('franchise_ignore', franchiseId)
  )
  fetch(`${URL}/product/${id}/`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 
