import { combineReducers } from 'redux';
import * as types from '../types/chain';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_CHAINS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          chains,
        },
      } = action;
      const chainState = {}
      for (let i in chains) {
        chainState[chains[i].id] = {
          ...chains[i],
        }
      }
      return chainState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_CHAINS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          chains,
        },
      } = action;
      const chainState = Object.values(chains).map((noticia) => noticia.id);
      return chainState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getChainById = (state, id) => state.byId[id] || undefined; 
export const getAllChains = (state) => state.order.map((id) => getChainById(state, id));

