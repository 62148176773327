import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/activity';
import * as api from '../apis/activity';
import * as actions from '../actions/activity';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* activitiesFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {

    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllActivities,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllActivitiesConfirm({
        activities: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllActivitiesDecline({
      message: error.message,
    }));
  }
}

function* activityHistoryFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      activityId,
      fromDate,
      toDate,
    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchActivityHistory,
      token,
      activityId,
      fromDate,
      toDate,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchActivityHistoryConfirm({
        records: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchActivityHistoryDecline({
      message: error.message,
    }));
  }
}

function* activityHistoryDownloader(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      activityId,
      fromDate,
      toDate,
    },
  } = action;
  try {
    const { logout } = yield call(
      api.downloadActivityHistory,
      token,
      activityId,
      fromDate,
      toDate,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.downloadActivityHistoryConfirm({
      }));
    }
  } catch (error) {
    yield put(actions.downloadActivityHistoryDecline({
      message: error.message,
    }));
  }
}

function* activitiesCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      picture,
      name,
      quantity_of_points,
      game_time,
      link,
      limit_date,
      questions,
      franchise,
      max_entrys,
      date,
      time
    }
  } = action;
  {console.log(date,time)}
  const formatedQuestions = questions.map((question) => {
    const possible_answers = [
      {
        answer: question.opcion1,
      },
      {
        answer: question.opcion2,
      },
      {
        answer: question.opcion3,
      },
      {
        answer: question.opcion4,
      },
    ];
    possible_answers[question.respuesta - 1] = {
      ...possible_answers[question.respuesta - 1],
      correct_answer: true,
    }
    return ({
      question: question.pregunta,
      possible_answers,
    })
  })
  try {
    const { response, logout } = yield call(
      api.createActivity,
      token,
      name,
      quantity_of_points,
      game_time, 
      link,
      limit_date,
      formatedQuestions,
      franchise,
      max_entrys,
      date,
      time
    );
    if (picture) {
      yield call(
        api.updateActivityImage,
        token,
        response.message.id,
        picture,
      );
    };
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createActivityConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllActivities());
    }
  } catch (error) {
    yield put(actions.createActivityDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* activitiesEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteActivity,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteActivityConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllActivities());
    }
  } catch (error) {
    yield put(actions.deleteActivityDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* activitiesUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      picture,
      name,
      link_to_activities,
      quantity_of_points,
      game_time,
      limit_date,
      max_entrys
    }
  } = action;
  try {
    if (picture) {
      yield call(
        api.updateActivityImage,
        token,
        id,
        picture,
      );
    };
    const { /*response,*/ logout } = yield call(
      api.updateActivity,
      token,
      id,
      name,
      link_to_activities,
      quantity_of_points,
      game_time,
      limit_date,
      max_entrys
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteActivityConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllActivities());
    }
  } catch (error) {
    yield put(actions.deleteActivityDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* ActivitiesSaga() {
  yield takeLatest(
    types.ALL_ACTIVITIES_FETCHED,
    activitiesFetcher,
  );
  yield takeLatest(
    types.ACTIVITY_HISTORY_FETCHED,
    activityHistoryFetcher,
  );
  yield takeLatest(
    types.ACTIVITY_HISTORY_DOWNLOADED,
    activityHistoryDownloader,
  );
  yield takeLatest(
    types.ACTIVITY_CREATED,
    activitiesCreator,
  );
  yield takeLatest(
    types.ACTIVITY_DELETED,
    activitiesEliminator,
  );
  yield takeLatest(
    types.ACTIVITY_UPDATED,
    activitiesUpdator,
  );
}
