import { combineReducers } from 'redux';
import * as types from '../types/promotion';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_PROMOTIONS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          promotions,
        },
      } = action;
      const promotionState = {}
      for (let i in promotions) {
        promotionState[promotions[i].id] = {
          ...promotions[i],
        }
      }
      return promotionState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_PROMOTIONS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          promotions,
        },
      } = action;
      const promotionState = Object.values(promotions).map((noticia) => noticia.id);
      return promotionState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getPromotionById = (state, id) => state.byId[id] || undefined; 
export const getAllPromotions = (state) => state.order.map((id) => getPromotionById(state, id));

