import URL from './routes';

export const fetchAllBranchs = (
  token,
  chainId,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/branch/?franchise=${chainId}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const createBranch = (
  token,
  path_to_picture,
  name,
  link_to_branch,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  path_to_picture && data.append('picture', path_to_picture);
  name && data.append('name', name);
  link_to_branch && data.append('link', link_to_branch);
  fetch(`${URL}/branch/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const deleteBranch = (
  token,
  id,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/branch/${id}/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resolve({
        logout: false,
      });
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateBranch = (
  token,
  id,
  path_to_picture,
  name,
  link_to_branch,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  path_to_picture && data.append('path_to_picture', path_to_picture);
  name && data.append('name', name);
  link_to_branch && data.append('link_to_branch', link_to_branch);
  fetch(`${URL}/branch/${id}/`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 
