import { combineReducers } from 'redux';
import * as types from '../types/activity';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_ACTIVITIES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          activities,
        },
      } = action;
      const newState = {}
      for (let i in activities) {
        const questions = activities[i].possible_answers.map((question) => {
          const options = {};
          let answer = undefined;
          question.possible_answers.forEach((option, i) => {
            options[`opt${i}`] = option.answer;
            if (answer === undefined && option.correct_answer) {
              answer = i + 1;
            }
          })
          return {
            ...options,
            question: question.question,
            answer,
          };
        });
        newState[activities[i].id] = {
          ...activities[i],
          questions,
          franchise: activities[i].franchise ? activities[i].franchise.name : "TODOS",
        }
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_ACTIVITIES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          activities,
        },
      } = action;
      const newState = Object.values(activities).map((actividad) => actividad.id);
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getActivityById = (state, id) => state.byId[id] || undefined; 
export const getAllActivities = (state) => state.order.map((id) => getActivityById(state, id));

