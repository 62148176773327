import URL from './routes';

export const fetchAllUsers = (
  token,
  name,
  erpId
) => new Promise((resolve, reject) => {
  let params = {}
  params = name ? {...params, name} : {...params}
  params = erpId ? {...params, erpId} : {...params}
  fetch(`${URL}/user_admin/?` + new URLSearchParams(params), {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const fetchOneUser = (
  token,
  id
) => new Promise((resolve, reject) => {
  let params = {}
  //params = name ? {...params, name} : {...params}
  //params = erpId ? {...params, erpId} : {...params}
  fetch(`${URL}/user_admin/${id}/?` + new URLSearchParams(params), {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const downloadAllUsers = (
  jwt, 
  name,
  erpId
) => new Promise((resolve, reject) => {
  let params = {}
  params = name ? {...params, name} : {...params}
  params = erpId ? {...params, erpId} : {...params}
  fetch(`${URL}/user/report/?` + new URLSearchParams(params), {
    headers: {
      "Authorization": "Token "+jwt
    },
    method: 'GET',
  }).then((resultado) =>  {
    if (resultado.ok) {
      resultado.blob()
      .then( blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const date = new Date();
        const juntado = `Dependientes ${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}.xlsx`
        a.download = juntado
        document.body.appendChild(a); 
        a.click();    
        a.remove(); 
      });
      resolve({
        logout: false,
      })
    } else {
      resultado.json().then((res) => resolve({
        response: res,
        error: true,
        logout: resultado.status === 401,
      }));
    }
  }).catch((error) => reject(error));
  });

export const createUser = (
  token,
  projId,
  name
) => new Promise((resolve, reject) => {
  const data = new FormData();
  name && data.append('name', name);
  projId && data.append('project', projId);
  fetch(`${URL}/user/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const confirmUser = (
  token,
  id,
  erp_id,
  level,
  status,
  coins,
  dpi,
  email,
  birthday,
  phone,
  points,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  id && data.append('user_id', id);
  erp_id && data.append('erp_id', erp_id);
  level && data.append('level_id', level);
  status && data.append('flag', status);
  coins && data.append('coins', coins);
  dpi && data.append('dpi', dpi);
  email && data.append('email', email);
  birthday && data.append('birthday', birthday);
  phone && data.append('phone', phone);
  points && data.append('points', points);
  fetch(`${URL}/user/change_status_user/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const deleteUser = (
  token,
  id,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  id && data.append('user_id', id);
  fetch(`${URL}/user/delete/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resolve({
        logout: false,
      });
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updateUser = (
  token,
  projId,
  id,
  name,
) => new Promise((resolve, reject) => {
  const proj = projId ? `?projId=${projId}` : '';
  const data = new FormData();
  name && data.append('name', name);
  fetch(`${URL}/user/${id}/${proj}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 
