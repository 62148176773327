import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import readXlsxFile from 'read-excel-file'
import { getUserToken } from '../reducers';
import * as types from '../types/promotion';
import * as api from '../apis/promotion';
import * as actions from '../actions/promotion';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

const readValuesFromFile = (
  file,
) => new Promise((resolve, reject) => {
  readXlsxFile(file).then((rows) => {
    resolve(rows)
  }).catch((error) => reject(error));
})


function* promotionsFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {

    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllPromotions,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllPromotionsConfirm({
        promotions: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllPromotionsDecline({
      message: error.message,
    }));
  }
}

function* promotionsCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      picture,
      dpis,
      date_to_expire,
      message,
      title,
      date,
      time
    }
  } = action;
  const values = yield call(
    readValuesFromFile,
    dpis
  )
  const newDpis = values.map((value) => value[0]).join()
  try {
    const { logout } = yield call(
      api.createPromotion,
      token,
      picture,
      newDpis,
      date_to_expire,
      message,
      title,
      date,
      time
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createPromotionConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllPromotions());
    }
  } catch (error) {
    yield put(actions.createPromotionDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: error.message,
    }))
  }
}

function* promotionsEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deletePromotion,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deletePromotionConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllPromotions());
    }
  } catch (error) {
    yield put(actions.deletePromotionDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* promotionsUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      path_to_picture,
      message,
      title,
      date_to_expire,
    }
  } = action;
  try {
    if (path_to_picture) {
      yield call(
        api.updatePromotionImage,
        token,
        id,
        path_to_picture,
      );
    };
    const { /*response*/ logout } = yield call(
      api.updatePromotion,
      token,
      id,
      message,
      title,
      date_to_expire,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.updatePromotionConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllPromotions());
    }
  } catch (error) {
    yield put(actions.updatePromotionDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* PromotionsSaga() {
  yield takeLatest(
    types.ALL_PROMOTIONS_FETCHED,
    promotionsFetcher,
  );
  yield takeLatest(
    types.PROMOTION_CREATED,
    promotionsCreator,
  );
  yield takeLatest(
    types.PROMOTION_DELETED,
    promotionsEliminator,
  );
  yield takeLatest(
    types.PROMOTION_UPDATED,
    promotionsUpdator,
  );
}
