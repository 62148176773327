import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken, getProjId } from '../reducers';
import * as types from '../types/category';
import * as api from '../apis/category';
import * as actions from '../actions/category';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* categoriesFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {

    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllCategories,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllCategoriesConfirm({
        categories: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllCategoriesDecline({
      message: error.message,
    }));
  }
}

function* categoriesPrizesFetcher(action) {
  const token = yield select(getUserToken);
  try {
    const { response, logout } = yield call(
      api.fetchAllPrizesCategories,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllPrizesCategoriesConfirm({
        categories: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllPrizesCategoriesDecline({
      message: error.message,
    }));
  }
}

function* categoriesBenefitsFetcher(action) {
  const token = yield select(getUserToken);
  try {
    const { response, logout } = yield call(
      api.fetchAllBenefitsCategories,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllPrizesCategoriesConfirm({
        categories: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllPrizesCategoriesDecline({
      message: error.message,
    }));
  }
}

function* categoriesCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      path_to_picture,
      name,
      priority,
      for_prize,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createCategory,
      token,
      path_to_picture,
      name,
      priority,
      for_prize
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createCategoryConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllCategories());
    }
  } catch (error) {
    yield put(actions.createCategoryDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* categoriesEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteCategory,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteCategoryConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllCategories());
    }
  } catch (error) {
    yield put(actions.deleteCategoryDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* categoriesUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      path_to_picture,
      name,
      priority,
    }
  } = action;
  try {
    if (path_to_picture) {
      yield call(
        api.updateCategoryImage,
        token,
        id,
        path_to_picture,
      );
    };
    const { /*response,*/ logout } = yield call(
      api.updateCategory,
      token,
      id,
      name,
      priority,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteCategoryConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllCategories());
    }
  } catch (error) {
    yield put(actions.deleteCategoryDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* CategoriesSaga() {
  yield takeLatest(
    types.ALL_CATEGORIES_FETCHED,
    categoriesFetcher,
  );
  yield takeLatest(
    types.CATEGORY_CREATED,
    categoriesCreator,
  );
  yield takeLatest(
    types.CATEGORY_DELETED,
    categoriesEliminator,
  );
  yield takeLatest(
    types.CATEGORY_UPDATED,
    categoriesUpdator,
  );
  yield takeLatest(
    types.ALL_PRIZES_CATEGORIES_FETCHED,
    categoriesPrizesFetcher,
  );
  yield takeLatest(
    types.ALL_BENEFITS_CATEGORIES_FETCHED,
    categoriesBenefitsFetcher,
  )
}
