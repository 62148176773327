import * as types from '../types/branch';

export const fetchAllBranchs = ({
  chainId,
}) => ({
  type: types.ALL_BRANCHS_FETCHED,
  payload: {
    chainId
  },
});

export const fetchAllBranchsConfirm = ({
  branchs,
}) => ({
  type: types.ALL_BRANCHS_FETCHED_SUCCEEDED,
  payload: {
    branchs,
  },
});

export const fetchAllBranchsDecline = ({
  message,
}) => ({
  type: types.ALL_BRANCHS_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const createBranch = ({
  path_to_picture,
  name,
  link_to_branchs,
}) => ({
  type: types.BRANCH_CREATED,
  payload: {
    path_to_picture,
    name,
    link_to_branchs,
  },
});

export const createBranchConfirm = () => ({
  type: types.BRANCH_CREATED_SUCCEEDED,
  payload: {},
});

export const createBranchDecline = ({
  message,
}) => ({
  type: types.BRANCH_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteBranch = ({
  id,
}) => ({
  type: types.BRANCH_DELETED,
  payload: {
    id,
  },
});

export const deleteBranchConfirm = ({
  data,
}) => ({
  type: types.BRANCH_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteBranchDecline = ({
  message,
}) => ({
  type: types.BRANCH_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateBranch = ({
  id,
  path_to_picture,
  name,
  link_to_branchs,
}) => ({
  type: types.BRANCH_UPDATED,
  payload: {
    id,
    path_to_picture,
    name,
    link_to_branchs,
  },
});

export const updateBranchConfirm = ({
  data,
}) => ({
  type: types.BRANCH_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateBranchDecline = ({
  message,
}) => ({
  type: types.BRANCH_UPDATED_FAILED,
  payload: {
    message,
  },
});
