import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import styles from './formselect.module.css';

const FormSelect = ({
  input: { onChange },
  placeholder,
  value,
  options,
  isMulti
}) => (
  <Select
    className={`react-select info ${styles.myselect}`}
    classNamePrefix="react-select"
    onChange={value => {
      onChange(isMulti ? value ? value.map(element => element.value): undefined : value.value)
    }}
    value={value}
    options={options}
    placeholder={placeholder}
    isMulti={isMulti}
  />
);

FormSelect.propTypes = {
  // cambiar a un objeto
  input: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default FormSelect;
