import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import FormDate from '../General/FormDate';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import * as selectors from '../../reducers';
import * as actions from '../../actions/exchange';

const header = [
  {
    text: 'Cadena',
    name: 'franchise',
    type: 'string',
  },
  {
    text: 'Sucursal',
    name: 'branch',
    type: 'string',
  },
  {
    text: 'Beneficio',
    name: 'benefit',
    type: 'string',
  },
  {
    text: 'Telefono',
    name: 'phone',
    type: 'string',
  },
  {
    text: 'Tipo',
    name: 'type',
    type: 'string',
  },
  {
    text: 'Dependiente',
    name: 'dependiente',
    type: 'string',
  },
  {
    text: 'CODIGO',
    name: 'id',
    type: 'string',
  },
  {
    text: 'Fecha',
    name: 'exchange_at',
    type: 'string',
  },
];

class Exchanges extends Component {
  constructor(props){
    super(props)
    this.state = {
      fromDate: undefined,
      toDate: undefined,
    }
  }
  componentDidMount(){
    const {
      fetchExchanges,
    } = this.props;
    fetchExchanges({});
  }

  changeDate(date, value){
    this.setState({
      [date]: value
    })
  }

  render() {
    const {
      exchanges,
      fetchExchanges,
      downloadExchanges
    } = this.props;
    const {
      fromDate,
      toDate,
    } = this.state;
    return (
      <>
        <Card>
            <CardBody>
              <CardTitle>
                Lista de pendientes:
              </CardTitle>
              <Row>
                <Col sm="2">
                  <FormDate 
                    placeholder="desde"
                    value={fromDate}
                    input={{
                      onChange: (value) => this.changeDate("fromDate", value)
                    }}
                  />
                </Col>
                <Col sm="2">
                  <FormDate 
                    placeholder="hasta"
                    value={toDate}
                    input={{
                      onChange: (value) => this.changeDate("toDate", value)
                    }}
                  />
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                    onClick={() => downloadExchanges({fromDate, toDate})}
                  >
                    Descargar
                  </Button>
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                    onClick={() => fetchExchanges({fromDate, toDate})}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        <Table
          title="Canjes"
          header={header}
          data={exchanges}
        />
      </>
  );
  }
}

export default connect(
  (state) => ({
    exchanges: selectors.getAllExchanges(state),
  }),
  (dispatch) => ({
    fetchExchanges(values) {
      dispatch(actions.fetchAllExchanges({
        fromDate: values.fromDate,
        toDate: values.toDate,
      }))
    },
    downloadExchanges(values) {
      dispatch(actions.downloadAllExchanges({
        fromDate: values.fromDate,
        toDate: values.toDate,
      }))
    },
  })
)(Exchanges);
