export const ALL_ACTIVITIES_FETCHED = 'ALL_ACTIVITIES_FETCHED';
export const ALL_ACTIVITIES_FETCHED_SUCCEEDED = 'ALL_ACTIVITIES_FETCHED_SUCCEEDED';
export const ALL_ACTIVITIES_FETCHED_FAILED = 'ALL_ACTIVITIES_FETCHED_FAILED';

export const ACTIVITY_HISTORY_FETCHED = 'ACTIVITY_HISTORY_FETCHED';
export const ACTIVITY_HISTORY_FETCHED_SUCCEEDED = 'ACTIVITY_HISTORY_FETCHED_SUCCEEDED';
export const ACTIVITY_HISTORY_FETCHED_FAILED = 'ACTIVITY_HISTORY_FETCHED_FAILED';

export const ACTIVITY_HISTORY_DOWNLOADED = 'ACTIVITY_HISTORY_DOWNLOADED';
export const ACTIVITY_HISTORY_DOWNLOADED_SUCCEEDED = 'ACTIVITY_HISTORY_DOWNLOADED_SUCCEEDED';
export const ACTIVITY_HISTORY_DOWNLOADED_FAILED = 'ACTIVITY_HISTORY_DOWNLOADED_FAILED';

export const ACTIVITY_CREATED = 'ACTIVITY_CREATED';
export const ACTIVITY_CREATED_SUCCEEDED = 'ACTIVITY_CREATED_SUCCEEDED';
export const ACTIVITY_CREATED_FAILED = 'ACTIVITY_CREATED_FAILED';

export const ACTIVITY_DELETED = 'ACTIVITY_DELETED';
export const ACTIVITY_DELETED_SUCCEEDED = 'ACTIVITY_DELETED_SUCCEEDED';
export const ACTIVITY_DELETED_FAILED = 'ACTIVITY_DELETED_FAILED';

export const ACTIVITY_UPDATED = 'ACTIVITY_UPDATED';
export const ACTIVITY_UPDATED_SUCCEEDED = 'ACTIVITY_UPDATED_SUCCEEDED';
export const ACTIVITY_UPDATED_FAILED = 'ACTIVITY_UPDATED_FAILED';
