import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/exchangePrize';
import * as api from '../apis/exchangePrize';
import * as actions from '../actions/exchangePrize';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* exchangesPrizesFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      fromDate,
      toDate,
    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllExchangesPrizes,
      token,
      fromDate,
      toDate,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllExchangesPrizesConfirm({
        exchangesPrizes: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllExchangesPrizesDecline({
      message: error.message,
    }));
  }
}

function* exchangesPrizesDownloader(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      fromDate,
      toDate,
    },
  } = action;
  try {
    const { logout } = yield call(
      api.downloadAllExchangesPrizes,
      token,
      fromDate,
      toDate,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.downloadAllExchangesPrizesConfirm({
      }));
    }
  } catch (error) {
    yield put(actions.downloadAllExchangesPrizesDecline({
      message: error.message,
    }));
  }
}

function* exchangesPrizesCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      path_to_picture,
      name,
      link_to_exchanges,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createExchangePrize,
      token,
      path_to_picture,
      name,
      link_to_exchanges,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createExchangePrizeConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllExchangesPrizes());
    }
  } catch (error) {
    yield put(actions.createExchangePrizeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* exchangesPrizesEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteExchangePrize,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteExchangePrizeConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllExchangesPrizes());
    }
  } catch (error) {
    yield put(actions.deleteExchangePrizeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* exchangesPrizesUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      path_to_picture,
      name,
      link_to_exchanges,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.updateExchangePrize,
      token,
      id,
      path_to_picture,
      name,
      link_to_exchanges,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteExchangePrizeConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllExchangesPrizes());
    }
  } catch (error) {
    yield put(actions.deleteExchangePrizeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* ExchangesPrizesSaga() {
  yield takeLatest(
    types.ALL_EXCHANGESPRIZES_FETCHED,
    exchangesPrizesFetcher,
  );
  yield takeLatest(
    types.ALL_EXCHANGESPRIZES_DOWNLOADED,
    exchangesPrizesDownloader,
  );
  yield takeLatest(
    types.EXCHANGEPRIZE_CREATED,
    exchangesPrizesCreator,
  );
  yield takeLatest(
    types.EXCHANGEPRIZE_DELETED,
    exchangesPrizesEliminator,
  );
  yield takeLatest(
    types.EXCHANGEPRIZE_UPDATED,
    exchangesPrizesUpdator,
  );
}
