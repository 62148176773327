import { combineReducers } from 'redux';
import * as types from '../types/benefit';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_LEVELS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          levels,
        },
      } = action;
      const levelState = {}
      for (let i in levels) {
        const level = levels[i];
        levelState[level.id] = {
          ...level,
        }
      }
      return levelState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_LEVELS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          levels,
        },
      } = action;
      const levelState = Object.values(levels).map((level) => level.id);
      return levelState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getLevelById = (state, id) => state.byId[id] || undefined; 
export const getAllLevels = (state) => state.order.map((id) => getLevelById(state, id));

