import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field  } from 'redux-form';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
//import Card from "../General/Card/Card";
//import CardAvatar from "../General/Card/CardAvatar.js";
//import CardBody from "../General/Card/CardBody.js";
//import Button from '../General/Button';
import * as actions from "../../actions/modal";
import * as selectors from "../../reducers";
import FormInput from '../General/FormInput';
import FormFile from '../General/FormFile';
import FormSelect from '../General/FormSelect';
import FormDate from '../General/FormDate';

class UserModalCore extends Component {

  onSubmit(values) {
    const {
      updateAction,
      hideModal,
      row: {
        id,
      }
    } = this.props;
    if (values) {
      updateAction({
        id,
        ...values
      })
    }
    hideModal();
  }

  render() {
    const {
      type,
      hideModal,
      handleSubmit,
      deleteAction,
      row: {
        id,
        data
      },
    } = this.props;

    const rows = [
      [
        {
          label: "CADENA",
          type: "string",
          text: 'franchise',
          size: "3",
          disabled: true,
        },
        {
          label: "SUCURSAL",
          type: "string",
          text: 'branch',
          size: "3",
          disabled: true,
        },
        {
          label: "CORREO",
          type: "string",
          text: "email",
          size: "3",
        },
        {
          label: "TELEFONO",
          type: "string",
          text: "phone",
          size: "2",
        },
      ],
      [
        {
          label: "PRIMER NOMBRE",
          text: "first_name",
          type: "string",
          size: "2",
          disabled: true,
        },
        {
          label: "SEGUNDO NOMBRE",
          text: "second_name",
          type: "string",
          size: "2",
          disabled: true,
        },
        {
          label: "PRIMER APELLIDO",
          text: "first_last_name",
          type: "string",
          size: "2",
          disabled: true,
        },
        {
          label: "PRIMER APELLIDO",
          text: "second_last_name",
          type: "string",
          size: "2",
          disabled: true,
        },
        {
          label: "DPI",
          type: "string",
          text: "dpi",
          size: "3",
        },
      ],
      [
        {
          label: "EDUCACION",
          type: "string",
          text: "academic_level",
          size: "3",
          disabled: true,
        },
        {
          label: "PADRE DE FAMILIA",
          type: "string",
          text: "PARENT",
          size: "2",
          disabled: true,
        },
        {
          label: "SEXO",
          type: "string",
          text: "sex",
          size: "2",
          disabled: true,
        },
        {
          label: "FECHA N.",
          type: "date",
          text: "birthday",
          size: "2",
        },
        {
          label: "HIJOS",
          type: "string",
          text: "male_childs",
          size: "1",
          disabled: true,
        },
        {
          label: "HIJAS",
          type: "string",
          text: "female_childs",
          size: "1",
          disabled: true,
        },
      ],
      [
        {
          label: "GUSTOS Y PASATIEMPOS",
          text: "pleasures",
          type: "string",
          size: "4",
          disabled: true,
        },
        {
          label: "MEDIO TRANSPORTE",
          text: "transport",
          type: "string",
          size: "2",
          disabled: true,
        },
        {
          label: "EMPRESA TELEFONICA",
          text: "thelephone_company",
          type: "string",
          size: "2",
          disabled: true,
        },
        {
          label: "TIPO CELULAR",
          text: "os",
          type: "string",
          size: "2",
          disabled: true,
        },
      ],
      [
        {
          label: "NIVEL",
          text: "level",
          type: "string",
          size: "3",
          disabled: true,
        },
        {
          label: "Puntos",
          type: "string",
          text: "points",
          size: "2",
          //disabled: data && data.status === 'Active',
          //disabled: true,
        },
        {
          label: "MONEDAS",
          type: "string",
          text: "coins",
          size: "2",
          //disabled: data && data.status === 'Active',
          //disabled: true,
        },
        {
          label: "CODIGO(ERP)",
          text: "erp_id",
          type: "string",
          size: "2",
          //disabled: data && data.status === 'Active',
        },
        {
          label: "ESTADO",
          type: "select",
          text: "status",
          options: [
            {
              value: 'A',
              label: 'ACTIVAR',
            },
            {
              value: 'R',
              label: 'CANCELAR',
            },
            {
              value: 'I',
              label: 'BLOQUEAR',
            },
          ],
          size: "2",
        },
      ]
    ]

    return (
    <>
    {
      type === "usersModal" && (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px", width: "1000px", overflow:'visible' }}
          title="Actualiza los datos"
          onConfirm={handleSubmit(this.onSubmit.bind(this))}
          onCancel={() => hideModal()}
          cancelBtnBsStyle="success"
          btnSize=""
          showCancel
        >
          <>
          </>
          <Card>
            <CardBody>
              <Form>
                {
                  rows.map((row) => (
                    <Row>
                    {
                      row.map((column) => (
                        <Col className="pr-md-1" md={column.size}>
                          <FormGroup>
                            <label>{column.label}</label>
                            {
                              column.type === "file" && (
                                <Field
                                  name={column.text} //data[column.name]
                                  component={FormFile}
                                  placeholder={data[column.text]}
                                  disabled={column.disabled}
                                />
                              )
                            } 
                            {
                              column.type === "select" && (
                                <Field
                                  name={column.text}
                                  component={FormSelect}
                                  placeholder={data[column.text]}
                                  options={column.options}
                                  disabled={column.disabled}
                                />
                              )
                            } 
                            {
                              column.type === "string" && (
                                <Field
                                  name={column.text}
                                  component={FormInput}
                                  type="text"
                                  placeholder={data[column.text]}
                                  disabled={column.disabled}
                                />
                              )
                            }
                            {
                              column.type === "date" && (
                                <Field
                                name={column.text}
                                  component={FormDate}
                                  placeholder={data[column.text]}
                                  disabled={column.disabled}
                                />
                              )
                            }
                          </FormGroup>
                        </Col>
                      ))
                    }
                    </Row>
                  ))
                }
              </Form>
            </CardBody>
          </Card>
        </ReactBSAlert>
      )
    }
    {
      type === "usersDeleteModal" && (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px", width: "1000px", overflow:'visible' }}
          title="Desea eliminar los datos?"
          onConfirm={() => deleteAction(id)}
          onCancel={() => hideModal()}
          cancelBtnBsStyle="success"
          btnSize=""
          showCancel
        >
        </ReactBSAlert>
      )
    }
    </>
    )
  }
}

const Modal = reduxForm({
  form: 'UserModal'
})(UserModalCore);

export default connect(
  (state) => ({
    type: selectors.getModalType(state), 
    row: selectors.getModalRowData(state),
  }),
  (dispatch) => ({
    hideModal() {
      dispatch(actions.hideModal());
    }
  }),
)(Modal);
