import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken, getProjId } from '../reducers';
import * as types from '../types/prize';
import * as api from '../apis/prize';
import * as actions from '../actions/prize';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* prizesFetcher(action) {
  const token = yield select(getUserToken);
  try {
      const  {response}= yield call(
      api.fetchAllPrizes,
      token,
    );
    /*if (logout) {
      yield put(loginActions.userLogout());
    } else {*/
      yield put(actions.fetchAllPrizesConfirm({
        prizes: response,
      }));
    //}
  } catch (error) {
    console.log(error)
    yield put(actions.fetchAllPrizesDecline({
      message: error.message,
      
    }));
  }
}

function* prizesCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      picture,
      name,
      price,
      category,
      type,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createPrize,
      token,
      picture,
      name,
      price,
      category,
      type,
    );
    if (logout) {
      yield put(loginActions.userLogout());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
    } else {
      yield put(actions.createPrizeConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllPrizes());
    }
  } catch (error) {
    yield put(actions.createPrizeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* prizesEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deletePrize,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deletePrizeConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllPrizes());
    }
  } catch (error) {
    yield put(actions.deletePrizeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* prizesUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      picture,
      name,
      price,
      category,
      type,
    }
  } = action;
  try {
    if (picture) {
      yield call(
        api.updatePrizeImage,
        token,
        id,
        picture,
      );
    };
      const { /*response,*/ logout } = yield call(
      api.updatePrize,
      token,
      id,
      name,
      price,
      category,
      type,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deletePrizeConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllPrizes());
    }
  } catch (error) {
    yield put(actions.deletePrizeDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* PrizesSaga() {
  yield takeLatest(
    types.ALL_PRIZES_FETCHED,
    prizesFetcher,
  );
  yield takeLatest(
    types.PRIZE_CREATED,
    prizesCreator,
  );
  yield takeLatest(
    types.PRIZE_DELETED,
    prizesEliminator,
  );
  yield takeLatest(
    types.PRIZE_UPDATED,
    prizesUpdator,
  ); 
}
