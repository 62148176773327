import { combineReducers } from 'redux';
import * as types from '../types/user';

const respDict = {
  "A" : "Activado",
  "R" :  "Solicitando",
  "I" :  "inactivo",
  "D" :  "eliminado",
}
const respDictOne = {
  "Active" : "Activado",
  "Requested" :  "Solicitando",
  "Inactive" :  "inactivo",
  "Deleted" :  "eliminado",
}

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_USERS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          users,
        },
      } = action;
      const newState = {}
      users.forEach((user, key) => {
        newState[user.id] = {
          key,
          ...user,
          status: respDict[user.status],
          //PARENT: user.male_childs + user.female_childs > 0 ? 'Si' : 'No',
          branch: user.branch__name === null ? undefined : user.branch__name,
          franchise: user.branch__franchise__name === null ? undefined : user.branch__franchise__name,
          //level: user.level === null ? '' : user.level.name,
        }
      })
      return newState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_USERS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          users,
        },
      } = action;
      const newState = Object.values(users).map((user) => user.id);
      return newState;
    }
    default: {
      return state;
    }
  }
}


const OneUser = (state={}, action) => {
  switch (action.type) {
    case types.ONE_USER_FETCHED_SUCCEEDED: {
      const {
        payload: {
          users,
        },
      } = action;
      const newState = {users}
      users.status = respDictOne[users.status]
      users.PARENT = users.childs ? 'Si' : 'No'
      users.franchise= users.branch && users.branch.franchise && users.branch.franchise.name === null ? undefined : users.branch.franchise.name
      users.branch = users.branch.name === null ? undefined : users.branch.name
      users.level =  users.level === null ? '' : users.level.name
      /* users.forEach((user, key) => {
        newState[user.id] = {
          key,
          ...user,
          status: respDict[user.status],
          //PARENT: user.male_childs + user.female_childs > 0 ? 'Si' : 'No',
          branch: user.branch__name === null ? undefined : user.branch__name,
          franchise: user.branch__franchise__name === null ? undefined : user.branch__franchise__name,
          //level: user.level === null ? '' : user.level.name,
        }
      }) */
      return users;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order,
  OneUser
})

//selectores
export const getUserById = (state, id) => state.byId[id] || undefined; 
export const getAllUsers = (state) => state.order.map((id) => getUserById(state, id));
export const getONEUsers = (state) => state;

