import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm  } from 'redux-form';
import { compose } from 'recompose';
import UsersModal from '../UsersModal';
import FormInput from '../General/FormInput'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import ExtendedTable from '../General/ExtendedTable';

import * as selectors from '../../reducers';
import * as actions from '../../actions/user';
import * as modalActions from '../../actions/modal';

const header = [
  {
    text: 'Num',
    name: 'key',
    type: 'string',
  },
  {
    text: 'Cadena',
    name: 'franchise',
    type: 'string',
  },
  {
    text: 'Sucursal',
    name: 'branch',
    type: 'string',
  },
  {
    text: 'Nombre',
    name: 'first_name',
    type: 'string',
  },
  {
    text: 'Nombre',
    name: 'second_name',
    type: 'string',
  },
  {
    text: 'Apellido',
    name: 'first_last_name',
    type: 'string',
  },
  {
    text: 'Apellido',
    name: 'second_last_name',
    type: 'string',
  },
  {
    text: 'Telefono',
    name: 'phone',
    type: 'string',
  },
  {
    text: 'Estado',
    name: 'status',
    type: 'string',
  },
];

class Clients extends Component {
  constructor(props){
    super(props)
    this.state = {
      name: undefined,
      erpId: undefined,
    }
  }

  componentDidMount(){
    const {
      fetchClients
    } = this.props;
    fetchClients({});
  }

  changeState(date, value){
    this.setState({
      [date]: value
    })
  }

  render() {
    const {
      deleteClient,
      confirmClient,
      updateUser,
      showUsersModal,
      clients,
      client,
      showDeleteUsersModal,
      downloadClients,
      fetchClients,
      fetchOneClient
    } = this.props;
    const {
      name,
      erpId,
    } = this.state;
    return (
      <>
        <UsersModal
          updateAction={confirmClient.bind(this)}
          deleteAction={deleteClient.bind(this)}
        />
        <Card>
          <CardBody>
            <CardTitle>
              Lista de pendientes:
            </CardTitle>
            <Row>
              <Col sm="2">
                <FormInput 
                  placeholder="nombre"
                  value={name}
                  input={{
                    onChange: (value) => this.changeState("name", value),
                  }}
                />
              </Col>
              <Col sm="2">
                <FormInput 
                  placeholder="ERP"
                  value={erpId}
                  input={{
                    onChange: (value) => this.changeState("erpId", value),
                  }}
                />
              </Col>
              <Col sm="2">
                <Button 
                  color="info"
                  type="submit"
                  onClick={() => downloadClients({
                    name,
                    erpId,
                  })}
                >
                  Descargar
                </Button>
              </Col>
              <Col sm="2">
                <Button 
                  color="info"
                  type="submit"
                  onClick={() => fetchClients({
                    name,
                    erpId,
                  })}
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <ExtendedTable
          header={header}
          data={clients}
          deleteAction={showDeleteUsersModal.bind(this)}
          showModal={showUsersModal.bind(this)}
          updateActionUser={fetchOneClient.bind(this)}
        />
      </>
    );
  }
}

export default compose(
  reduxForm({
    form: "Clients"
  }),
  connect(
    (state) => ({
      clients: selectors.getAllUsers(state),
      client: selectors.getONEUsers(state),
    }),
    (dispatch) => ({
      fetchClients(values) {
        dispatch(actions.fetchAllUsers({
          name: values.name,
          erpId: values.erpId
        }));
      },
      downloadClients(values) {
        dispatch(modalActions.showLoading({
          message: "Espere un momento"
        }))
        dispatch(actions.downloadAllUsers({
          name: values.name,
          erpId: values.erpId
        }));
      },
      deleteClient(id) {
        dispatch(actions.deleteUser({
          id,
        }))
      },
      fetchOneClient(id) {
        dispatch(actions.fetchOneUsers({
          id,
        }))
      },
      confirmClient(values){ 
        dispatch(actions.confirmUser({
          id: values.id,
          erp_id: values.erp_id,
          level: values.level,
          status: values.status,
          coins: values.coins,
          points: values.points,
          dpi: values.dpi,
          email: values.email,
          birthday: values.birthday ?
          `${values.birthday.getFullYear()}-${values.birthday.getMonth()+1}-${values.birthday.getDate()}` :
          undefined, 
          phone: values.phone,
        }))
      },
      showUsersModal(id, row) {
        const {
          client,
        } = this.props;
        console.log(client.OneUser, 'row')
        dispatch(modalActions.showExtra({
          id: id,
          type: "usersModal",
          data: client.OneUser,
        }));
      },
      showDeleteUsersModal(id, row) {
        dispatch(modalActions.showExtra({
          id: id,
          type: "usersDeleteModal",
          data: row,
        }));
      },
    })
  )
)(Clients);
