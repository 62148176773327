
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import * as selectors from '../../reducers';
import * as actions from '../../actions/category';
import * as modalActions from '../../actions/modal';

const header = [
  {
    text: 'Imagen',
    name: 'path_to_picture',
    type: 'file',
  },
  {
    text: 'Nombre',
    name: 'name',
    type: 'string',
  },
  {
    text: 'Prioridad',
    name: 'priority',
    type: 'select',
    options: Array.apply(null, Array(10)).map((x ,id) => ({
      value: id + 1,
      label: `${id + 1}`
    }))
  },
  {
    text: 'Tipo',
    name: 'for_prize',
    type: 'select',
    options: [
      {
        value: true,
        label: 'Premio'
      },
      {
        value: false,
        label: 'Beneficio'
      },
    ]
  }
];


class Categories extends Component {
  componentDidMount(){
    const {
      fetchAllCategories
    } = this.props;
    fetchAllCategories();
  }

  render() {
    const {
      createCategory,
      deleteCategory,
      updateCategory,
      showCategoriesModal,
      categories,
    } = this.props;
    return (
      <Table
        title="Categoria"
        header={header}
        data={categories}
        createAction={createCategory.bind(this)}
        deleteAction={deleteCategory.bind(this)}
        showModal={showCategoriesModal.bind(this)}
        updateAction={updateCategory.bind(this)}
      />
  );
  }
}

export default connect(
  (state) => ({
    categories: selectors.getAllCategories(state),
  }),
  (dispatch) => ({
    fetchAllCategories() {
      dispatch(actions.fetchAllCategories());
    },
    createCategory(values) {
      dispatch(actions.createCategory({
        path_to_picture: values.path_to_picture,
        path_to_icon: values.path_to_icon,
        name: values.name,
        priority: values.priority,
        for_prize: values.for_prize
      }));
    },
    deleteCategory(id) {
      dispatch(actions.deleteCategory({
        id,
      }))
    },
    showCategoriesModal(id, data) {
      dispatch(modalActions.showUpdate({
        id: id,
        data: [
          {
            label: 'Fondo',
            name: 'path_to_picture',
            text: data.path_to_picture,
            type: 'file'
          },
          {
            label: 'Nombre',
            name: 'name',
            text: data.name,
            type: 'string'
          },
          {
            label: 'Prioridad',
            name: 'priority',
            type: 'select',
            options: Array.apply(null, Array(10)).map((x ,id) => ({
              value: id + 1,
              label: `${id + 1}`
            }))
          },
        ],
      }))
    },
    updateCategory(values){ 
      dispatch(actions.updateCategory({
        id: values.id,
        path_to_picture: values.path_to_picture,
        path_to_icon: values.path_to_icon,
        name: values.name,
        priority: values.priority,
      }))
    },
  })
)(Categories);
