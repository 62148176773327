import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/new';
import * as api from '../apis/new';
import * as actions from '../actions/new';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* newsFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {

    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllNews,
      token,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllNewsConfirm({
        news: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllNewsDecline({
      message: error.message,
    }));
  }
}

function* newsCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      path_to_picture,
      name,
      link_to_news,
      franchise,
      date,
      time
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createNew,
      token,
      path_to_picture,
      name,
      link_to_news,
      franchise,
      date,
      time
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createNewConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllNews());
    }
  } catch (error) {
    yield put(actions.createNewDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* newsEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteNew,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteNewConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllNews());
    }
  } catch (error) {
    yield put(actions.deleteNewDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* newsUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      path_to_picture,
      name,
      link_to_news,
      franchise,
    }
  } = action;
  try {
    if (path_to_picture) {
      yield call(
        api.updateNewImage,
        token,
        id,
        path_to_picture,
      );
    };
    const { /*response,*/ logout } = yield call(
      api.updateNew,
      token,
      id,
      name,
      link_to_news,
      franchise,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteNewConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllNews());
    }
  } catch (error) {
    yield put(actions.deleteNewDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* NewsSaga() {
  yield takeLatest(
    types.ALL_NEWS_FETCHED,
    newsFetcher,
  );
  yield takeLatest(
    types.NEW_CREATED,
    newsCreator,
  );
  yield takeLatest(
    types.NEW_DELETED,
    newsEliminator,
  );
  yield takeLatest(
    types.NEW_UPDATED,
    newsUpdator,
  );
}
