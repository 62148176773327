import { combineReducers } from 'redux';
import * as types from '../types/exchangePrize';

const places = {
  'PHARMACY': 'Farmacia',
  'PLACE': 'Establecimiento',
  'NONE': '',
}

const byId = (state = {}, action) => {
  switch (action.type) {
    case types.ALL_EXCHANGESPRIZES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          exchangesPrizes,
        },
      } = action;

      const exchangePrizeState = {}
      for (let i in exchangesPrizes) {
        const createdDate = new Date(exchangesPrizes[i].created_at);
        const day = String(createdDate.getUTCDate()).padStart(2, '0');
        const month = String(createdDate.getUTCMonth() + 1).padStart(2, '0'); // Se suma 1 porque los meses van de 0 a 11
        const year = createdDate.getUTCFullYear();
        
        exchangePrizeState[exchangesPrizes[i].id] = {
          ...exchangesPrizes[i],
          prize: exchangesPrizes[i].prize ? exchangesPrizes[i].prize.name : "",
          picture: exchangesPrizes[i].prize ? exchangesPrizes[i].prize.picture : "",
          type: exchangesPrizes[i].prize ? places[exchangesPrizes[i].prize.exchange_place] : "",
          user: exchangesPrizes[i].exchanged_by ? exchangesPrizes[i].exchanged_by.first_name : '',
          phone: exchangesPrizes[i].exchanged_by ? exchangesPrizes[i].exchanged_by.phone : '',
          branch: exchangesPrizes[i].exchanged_by ? exchangesPrizes[i].exchanged_by.branch.name : "",
          level: exchangesPrizes[i].prize && exchangesPrizes[i].prize.level_to_get ? exchangesPrizes[i].prize.level_to_get.name : "",
          dependiente: exchangesPrizes[i].exchanged_by ?
            `${exchangesPrizes[i].exchanged_by.first_name} ${exchangesPrizes[i].exchanged_by.second_name} ${exchangesPrizes[i].exchanged_by.first_last_name} ${exchangesPrizes[i].exchanged_by.second_last_name}`
            :
            "",
          franchise: exchangesPrizes[i].exchanged_by ? exchangesPrizes[i].exchanged_by.branch.franchise.name : "",
          //exchange_at: new Date(exchangesPrizes[i].created_at).toLocaleDateString(),
          exchange_at: `${month}/${day}/${year}`,


        }
      }
      return exchangePrizeState;
    }
    default: {
      return state;
    }
  }
}

const order = (state = [], action) => {
  switch (action.type) {
    case types.ALL_EXCHANGESPRIZES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          exchangesPrizes,
        },
      } = action;
      const exchangePrizeState = Object.values(exchangesPrizes).map((noticia) => noticia.id);
      return exchangePrizeState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getExchangePrizeById = (state, id) => state.byId[id] || undefined;
export const getAllExchangesPrizes = (state) => state.order.map((id) => getExchangePrizeById(state, id));

