export const ALL_NEWS_FETCHED = 'ALL_NEWS_FETCHED';
export const ALL_NEWS_FETCHED_SUCCEEDED = 'ALL_NEWS_FETCHED_SUCCEEDED';
export const ALL_NEWS_FETCHED_FAILED = 'ALL_NEWS_FETCHED_FAILED';

export const NEW_CREATED = 'NEW_CREATED';
export const NEW_CREATED_SUCCEEDED = 'NEW_CREATED_SUCCEEDED';
export const NEW_CREATED_FAILED = 'NEW_CREATED_FAILED';

export const NEW_DELETED = 'NEW_DELETED';
export const NEW_DELETED_SUCCEEDED = 'NEW_DELETED_SUCCEEDED';
export const NEW_DELETED_FAILED = 'NEW_DELETED_FAILED';

export const NEW_UPDATED = 'NEW_UPDATED';
export const NEW_UPDATED_SUCCEEDED = 'NEW_UPDATED_SUCCEEDED';
export const NEW_UPDATED_FAILED = 'NEW_UPDATED_FAILED';
