import { fork, all } from 'redux-saga/effects';
import LoginSaga from './login';
import NewSaga from './new';
import CategorySaga from './category';
import BenefitSaga from './benefit';
import UserSaga from './user';
import ActivitySaga from './activity';
import BranchSaga from './branch';
import ChainSaga from './chain';
import PromotionSaga from './promotion';
import ProductSaga from './product';
import PrizeSaga from './prize';
import ExchangesSaga from './exchange';
import ExchangesPrizesSaga from './exchangePrize';
import ObserversSaga from './observer';

function* mainSaga() {
  yield all([
    fork(LoginSaga),
    fork(NewSaga),
    fork(CategorySaga),
    fork(BenefitSaga),
    fork(UserSaga),
    fork(ActivitySaga),
    fork(BranchSaga),
    fork(ChainSaga),
    fork(PromotionSaga),
    fork(ProductSaga),
    fork(PrizeSaga),
    fork(ExchangesSaga),
    fork(ExchangesPrizesSaga),
    fork(ObserversSaga),
  ]);
}

export default mainSaga;
