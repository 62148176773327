import * as types from '../types/modal';

export const showError = ({
  message,
  title,
}) => ({
  type: types.ERROR_SHOW,
  payload: {
    message,
    title,
  },
});

export const showSuccess = ({
  message,
  title,
}) => ({
  type: types.SUCCESSS_SHOW,
  payload: {
    message,
    title,
  },
});

export const showUpdate = ({
  id,
  data,
}) => ({
  type: types.UPDATE_SHOW,
  payload: {
    id,
    data,
  },
});

export const showLoading= ({
  message,
  title,
}) => ({
  type: types.LOADING_SHOW,
  payload: {
    message,
    title,
  },
});

export const showExtra = ({
  id,
  type,
  data,
}) => ({
  type: types.EXTRA_SHOW,
  payload: {
    id,
    type,
    data,
  },
});

export const hideModal = () => ({
  type: types.MODAL_HIDDEN,
  payload: {},
});
