import { combineReducers } from 'redux';
import * as types from '../types/product';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_PRODUCTS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          products,
        },
      } = action;
      const productState = {}
      for (let i in products) {
        productState[products[i].id] = {
          ...products[i],
        }
      }
      return productState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_PRODUCTS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          products,
        },
      } = action;
      const productState = Object.values(products).map((noticia) => noticia.id);
      return productState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getProductById = (state, id) => state.byId[id] || undefined; 
export const getAllProducts = (state) => state.order.map((id) => getProductById(state, id));

