export const ALL_BRANCHS_FETCHED = 'ALL_BRANCHS_FETCHED';
export const ALL_BRANCHS_FETCHED_SUCCEEDED = 'ALL_BRANCHS_FETCHED_SUCCEEDED';
export const ALL_BRANCHS_FETCHED_FAILED = 'ALL_BRANCHS_FETCHED_FAILED';

export const BRANCH_CREATED = 'BRANCH_CREATED';
export const BRANCH_CREATED_SUCCEEDED = 'BRANCH_CREATED_SUCCEEDED';
export const BRANCH_CREATED_FAILED = 'BRANCH_CREATED_FAILED';

export const BRANCH_DELETED = 'BRANCH_DELETED';
export const BRANCH_DELETED_SUCCEEDED = 'BRANCH_DELETED_SUCCEEDED';
export const BRANCH_DELETED_FAILED = 'BRANCH_DELETED_FAILED';

export const BRANCH_UPDATED = 'BRANCH_UPDATED';
export const BRANCH_UPDATED_SUCCEEDED = 'BRANCH_UPDATED_SUCCEEDED';
export const BRANCH_UPDATED_FAILED = 'BRANCH_UPDATED_FAILED';
