import { combineReducers } from 'redux';
import * as types from '../types/branch';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_BRANCHS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          branchs,
        },
      } = action;
      const branchState = {}
      for (let i in branchs) {
        branchState[branchs[i].id] = {
          ...branchs[i],
        }
      }
      return branchState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_BRANCHS_FETCHED_SUCCEEDED: {
      const {
        payload: {
          branchs,
        },
      } = action;
      const branchState = Object.values(branchs).map((noticia) => noticia.id);
      return branchState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getBranchById = (state, id) => state.byId[id] || undefined; 
export const getAllBranchs = (state) => state.order.map((id) => getBranchById(state, id));

