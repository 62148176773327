import * as types from '../types/login';

//const stateShape = {
//  id: undefined,
//  username: undefined,
//  token: "4e501cf57ffe75608cbb2b1fa58542eae61201e6",
//  authenticated: true,
//};

const stateShape = {
  id: undefined,
  username: undefined,
  token: undefined,
  authenticated: false,
};

export default (state = stateShape, action) => {
  switch (action.type) {
    case types.USER_LOGGED_SUCCEDED: {
      const {
        payload:{
          token
        }
      } = action;
      return {
        ...state,
        token,
        authenticated: true,
      };
    }
    case types.USER_LOGGED_FAILED:
    case types.USER_UNLOGGED:{
      return stateShape;
    }
    default: {
      return state;
    }
  }
};

export const isUserAuthenticated = (state) => (state.authenticated);
export const getUserToken = (state) => state.token;
