export const ALL_PRODUCTS_FETCHED = 'ALL_PRODUCTS_FETCHED';
export const ALL_PRODUCTS_FETCHED_SUCCEEDED = 'ALL_PRODUCTS_FETCHED_SUCCEEDED';
export const ALL_PRODUCTS_FETCHED_FAILED = 'ALL_PRODUCTS_FETCHED_FAILED';

export const PRODUCT_CREATED = 'PRODUCT_CREATED';
export const PRODUCT_CREATED_SUCCEEDED = 'PRODUCT_CREATED_SUCCEEDED';
export const PRODUCT_CREATED_FAILED = 'PRODUCT_CREATED_FAILED';

export const PRODUCT_DELETED = 'PRODUCT_DELETED';
export const PRODUCT_DELETED_SUCCEEDED = 'PRODUCT_DELETED_SUCCEEDED';
export const PRODUCT_DELETED_FAILED = 'PRODUCT_DELETED_FAILED';

export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
export const PRODUCT_UPDATED_SUCCEEDED = 'PRODUCT_UPDATED_SUCCEEDED';
export const PRODUCT_UPDATED_FAILED = 'PRODUCT_UPDATED_FAILED';
