import * as types from '../types/prize';

export const fetchAllPrizes = () => ({
  type: types.ALL_PRIZES_FETCHED,
  payload: {},
});

export const fetchAllPrizesConfirm = ({
  prizes,
}) => ({
  type: types.ALL_PRIZES_FETCHED_SUCCEEDED,
  payload: {
    prizes,
  },
});

export const fetchAllPrizesDecline = ({
  message,
}) => ({
  type: types.ALL_PRIZES_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const createPrize = ({
  picture,
  name,
  price,
  category,
  type,
}) => ({
  type: types.PRIZE_CREATED,
  payload: {
    picture,
    name,
    price,
    category,
    type,
  },
});

export const createPrizeConfirm = () => ({
  type: types.PRIZE_CREATED_SUCCEEDED,
  payload: {},
});

export const createPrizeDecline = ({
  message,
}) => ({
  type: types.PRIZE_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deletePrize = ({
  id,
}) => ({
  type: types.PRIZE_DELETED,
  payload: {
    id,
  },
});

export const deletePrizeConfirm = ({
  data,
}) => ({
  type: types.PRIZE_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deletePrizeDecline = ({
  message,
}) => ({
  type: types.PRIZE_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updatePrize = ({
  id,
  picture,
  name,
  price,
  category,  
  type,
}) => ({
  type: types.PRIZE_UPDATED,
  payload: {
    id,
    picture,
    name,
    price,
    category,
    type,
  },
});

export const updatePrizeConfirm = ({
  data,
}) => ({
  type: types.PRIZE_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updatePrizeDecline = ({
  message,
}) => ({
  type: types.PRIZE_UPDATED_FAILED,
  payload: {
    message,
  },
});
