import { combineReducers } from 'redux';
import * as types from '../types/activity';

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ACTIVITY_HISTORY_FETCHED_SUCCEEDED: {
      const {
        payload: {
          records,
        },
      } = action;
      const newState = {}
      for (let i in records) {
        newState[records[i].id] = {
          ...records[i],
          branch: records[i].player ? records[i].player.branch.name : "",
          franchise: records[i].player ? records[i].player.branch.franchise.name : "",
          dependiente: records[i].player ? 
          `${records[i].player.first_name} ${records[i].player.second_name} ${records[i].player.first_last_name} ${records[i].player.second_last_name}`
          :
          ''
        }
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ACTIVITY_HISTORY_FETCHED_SUCCEEDED: {
      const {
        payload: {
          records,
        },
      } = action;
      const newState = Object.values(records).map((actividad) => actividad.id);
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getActivityById = (state, id) => state.byId[id] || undefined; 
export const getActivityHistory = (state) => state.order.map((id) => getActivityById(state, id));

