import * as types from '../types/user';

export const fetchAllUsers = ({
  name,
  erpId,
}) => ({
  type: types.ALL_USERS_FETCHED,
  payload: {
    name,
    erpId,
  },
});

export const fetchAllUsersConfirm = ({
  users,
}) => ({
  type: types.ALL_USERS_FETCHED_SUCCEEDED,
  payload: {
    users,
  },
});

export const fetchAllUsersDecline = ({
  message,
}) => ({
  type: types.ALL_USERS_FETCHED_FAILED,
  payload: {
    message,
  },
});


export const fetchOneUsers = ({
  id,
}) => ({
  type: types.ONE_USER_FETCHED,
  payload: {
    id,
  },
});

export const fetchOneUsersConfirm = ({
  users,
}) => ({
  type: types.ONE_USER_FETCHED_SUCCEEDED,
  payload: {
    users,
  },
});

export const fetchOneUsersDecline = ({
  message,
}) => ({
  type: types.ONE_USER_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const downloadAllUsers = ({
  name,
  erpId,
}) => ({
  type: types.ALL_USERS_DOWNLOADED,
  payload: {
    name,
    erpId,
  },
});

export const downloadAllUsersConfirm = ({
  users,
}) => ({
  type: types.ALL_USERS_DOWNLOADED_SUCCEEDED,
  payload: {
    users,
  },
});

export const downloadAllUsersDecline = ({
  message,
}) => ({
  type: types.ALL_USERS_DOWNLOADED_FAILED,
  payload: {
    message,
  },
});

export const confirmUser = ({
  id,
  erp_id,
  level,
  status,
  coins,
  dpi,
  email,
  birthday,
  phone,
  points
}) => ({
  type: types.USER_CONFIRMED,
  payload: {
    id,
    erp_id,
    level,
    status,
    coins,
    dpi,
    email,
    birthday,
    phone,
    points
  },
});

export const confirmUserConfirm = () => ({
  type: types.USER_CONFIRMED_SUCCEEDED,
  payload: {},
});

export const confirmUserDecline = ({
  message,
}) => ({
  type: types.USER_CONFIRMED_FAILED,
  payload: {
    message,
  },
});

export const deleteUser = ({
  id,
}) => ({
  type: types.USER_DELETED,
  payload: {
    id,
  },
});

export const deleteUserConfirm = ({
  data,
}) => ({
  type: types.USER_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteUserDecline = ({
  message,
}) => ({
  type: types.USER_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateUser = ({
  data,
}) => ({
  type: types.USER_UPDATED,
  payload: {
    data,
  },
});

export const updateUserConfirm = ({
  data,
}) => ({
  type: types.USER_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateUserDecline = ({
  message,
}) => ({
  type: types.USER_UPDATED_FAILED,
  payload: {
    message,
  },
});
