export const ALL_BENEFITS_FETCHED = 'ALL_BENEFITS_FETCHED';
export const ALL_BENEFITS_FETCHED_SUCCEEDED = 'ALL_BENEFITS_FETCHED_SUCCEEDED';
export const ALL_BENEFITS_FETCHED_FAILED = 'ALL_BENEFITS_FETCHED_FAILED';

export const ALL_LEVELS_FETCHED = 'ALL_LEVELS_FETCHED';
export const ALL_LEVELS_FETCHED_SUCCEEDED = 'ALL_LEVELS_FETCHED_SUCCEEDED';
export const ALL_LEVELS_FETCHED_FAILED = 'ALL_LEVELS_FETCHED_FAILED';

export const BENEFIT_CREATED = 'BENEFIT_CREATED';
export const BENEFIT_CREATED_SUCCEEDED = 'BENEFIT_CREATED_SUCCEEDED';
export const BENEFIT_CREATED_FAILED = 'BENEFIT_CREATED_FAILED';

export const BENEFIT_DELETED = 'BENEFIT_DELETED';
export const BENEFIT_DELETED_SUCCEEDED = 'BENEFIT_DELETED_SUCCEEDED';
export const BENEFIT_DELETED_FAILED = 'BENEFIT_DELETED_FAILED';

export const BENEFIT_UPDATED = 'BENEFIT_UPDATED';
export const BENEFIT_UPDATED_SUCCEEDED = 'BENEFIT_UPDATED_SUCCEEDED';
export const BENEFIT_UPDATED_FAILED = 'BENEFIT_UPDATED_FAILED';
