import * as types from '../types/activity';

export const fetchAllActivities = () => ({
  type: types.ALL_ACTIVITIES_FETCHED,
  payload: {},
});

export const fetchAllActivitiesConfirm = ({
  activities,
}) => ({
  type: types.ALL_ACTIVITIES_FETCHED_SUCCEEDED,
  payload: {
    activities,
  },
});

export const fetchActivityHistoryDecline = ({
  message,
}) => ({
  type: types.ALL_ACTIVITIES_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const fetchActivityHistory = ({
  activityId,
  fromDate,
  toDate,
}) => ({
  type: types.ACTIVITY_HISTORY_FETCHED,
  payload: {
    activityId,
    fromDate,
    toDate,
  },
});

export const fetchActivityHistoryConfirm = ({
  records,
}) => ({
  type: types.ACTIVITY_HISTORY_FETCHED_SUCCEEDED,
  payload: {
    records,
  },
});

export const fetchAllActivitiesDecline = ({
  message,
}) => ({
  type: types.ACTIVITY_HISTORY_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const downloadActivityHistory = ({
  activityId,
  fromDate,
  toDate,
}) => ({
  type: types.ACTIVITY_HISTORY_DOWNLOADED,
  payload: {
    activityId,
    fromDate,
    toDate,
  },
});

export const downloadActivityHistoryConfirm = ({
}) => ({
  type: types.ACTIVITY_HISTORY_DOWNLOADED_SUCCEEDED,
  payload: {
  },
});

export const downloadActivityHistoryDecline = ({
  message,
}) => ({
  type: types.ACTIVITY_HISTORY_DOWNLOADED_FAILED,
  payload: {
    message,
  },
});

export const createActivity = ({
  picture,
  name,
  quantity_of_points,
  game_time,
  link,
  limit_date,
  questions,
  franchise,
  max_entrys,
  date,
  time
}) => ({
  type: types.ACTIVITY_CREATED,
  payload: {
    picture,
    name,
    quantity_of_points,
    game_time,
    link,
    limit_date,
    questions,
    franchise,
    max_entrys,
    date,
    time
  },
});

export const createActivityConfirm = () => ({
  type: types.ACTIVITY_CREATED_SUCCEEDED,
  payload: {},
});

export const createActivityDecline = ({
  message,
}) => ({
  type: types.ACTIVITY_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteActivity = ({
  id,
}) => ({
  type: types.ACTIVITY_DELETED,
  payload: {
    id,
  },
});

export const deleteActivityConfirm = ({
  data,
}) => ({
  type: types.ACTIVITY_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteActivityDecline = ({
  message,
}) => ({
  type: types.ACTIVITY_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateActivity = ({
  id,
  picture,
  name,
  limit_date,
  quantity_of_points,
  link,
  game_time,
  max_entrys
}) => ({
  type: types.ACTIVITY_UPDATED,
  payload: {
    id,
    picture,
    name,
    limit_date,
    quantity_of_points,
    link,
    game_time,
    max_entrys
  },
});

export const updateActivityConfirm = ({
  data,
}) => ({
  type: types.ACTIVITY_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateActivityDecline = ({
  message,
}) => ({
  type: types.ACTIVITY_UPDATED_FAILED,
  payload: {
    message,
  },
});
