export const ALL_CHAINS_FETCHED = 'ALL_CHAINS_FETCHED';
export const ALL_CHAINS_FETCHED_SUCCEEDED = 'ALL_CHAINS_FETCHED_SUCCEEDED';
export const ALL_CHAINS_FETCHED_FAILED = 'ALL_CHAINS_FETCHED_FAILED';

export const CHAIN_CREATED = 'CHAIN_CREATED';
export const CHAIN_CREATED_SUCCEEDED = 'CHAIN_CREATED_SUCCEEDED';
export const CHAIN_CREATED_FAILED = 'CHAIN_CREATED_FAILED';

export const CHAIN_DELETED = 'CHAIN_DELETED';
export const CHAIN_DELETED_SUCCEEDED = 'CHAIN_DELETED_SUCCEEDED';
export const CHAIN_DELETED_FAILED = 'CHAIN_DELETED_FAILED';

export const CHAIN_UPDATED = 'CHAIN_UPDATED';
export const CHAIN_UPDATED_SUCCEEDED = 'CHAIN_UPDATED_SUCCEEDED';
export const CHAIN_UPDATED_FAILED = 'CHAIN_UPDATED_FAILED';
