export const ALL_OBSERVERS_FETCHED = 'ALL_OBSERVERS_FETCHED';
export const ALL_OBSERVERS_FETCHED_SUCCEEDED = 'ALL_OBSERVERS_FETCHED_SUCCEEDED';
export const ALL_OBSERVERS_FETCHED_FAILED = 'ALL_OBSERVERS_FETCHED_FAILED';

export const ALL_OBSERVERS_DOWNLOADED = 'ALL_OBSERVERS_DOWNLOADED';
export const ALL_OBSERVERS_DOWNLOADED_SUCCEEDED = 'ALL_OBSERVERS_DOWNLOADED_SUCCEEDED';
export const ALL_OBSERVERS_DOWNLOADED_FAILED = 'ALL_OBSERVERS_DOWNLOADED_FAILED';

export const OBSERVER_CREATED = 'OBSERVER_CREATED';
export const OBSERVER_CREATED_SUCCEEDED = 'OBSERVER_CREATED_SUCCEEDED';
export const OBSERVER_CREATED_FAILED = 'OBSERVER_CREATED_FAILED';

export const OBSERVER_DELETED = 'OBSERVER_DELETED';
export const OBSERVER_DELETED_SUCCEEDED = 'OBSERVER_DELETED_SUCCEEDED';
export const OBSERVER_DELETED_FAILED = 'OBSERVER_DELETED_FAILED';

export const OBSERVER_UPDATED = 'OBSERVER_UPDATED';
export const OBSERVER_UPDATED_SUCCEEDED = 'OBSERVER_UPDATED_SUCCEEDED';
export const OBSERVER_UPDATED_FAILED = 'OBSERVER_UPDATED_FAILED';
