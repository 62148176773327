export const ALL_PRIZES_FETCHED = 'ALL_PRIZES_FETCHED';
export const ALL_PRIZES_FETCHED_SUCCEEDED = 'ALL_PRIZES_FETCHED_SUCCEEDED';
export const ALL_PRIZES_FETCHED_FAILED = 'ALL_PRIZES_FETCHED_FAILED';

export const PRIZE_CREATED = 'PRIZE_CREATED';
export const PRIZE_CREATED_SUCCEEDED = 'PRIZE_CREATED_SUCCEEDED';
export const PRIZE_CREATED_FAILED = 'PRIZE_CREATED_FAILED';

export const PRIZE_DELETED = 'PRIZE_DELETED';
export const PRIZE_DELETED_SUCCEEDED = 'PRIZE_DELETED_SUCCEEDED';
export const PRIZE_DELETED_FAILED = 'PRIZE_DELETED_FAILED';

export const PRIZE_UPDATED = 'PRIZE_UPDATED';
export const PRIZE_UPDATED_SUCCEEDED = 'PRIZE_UPDATED_SUCCEEDED';
export const PRIZE_UPDATED_FAILED = 'PRIZE_UPDATED_FAILED';
