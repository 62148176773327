export const ALL_EXCHANGESPRIZES_FETCHED = 'ALL_EXCHANGESPRIZES_FETCHED';
export const ALL_EXCHANGESPRIZES_FETCHED_SUCCEEDED = 'ALL_EXCHANGESPRIZES_FETCHED_SUCCEEDED';
export const ALL_EXCHANGESPRIZES_FETCHED_FAILED = 'ALL_EXCHANGESPRIZES_FETCHED_FAILED';

export const ALL_EXCHANGESPRIZES_DOWNLOADED = 'ALL_EXCHANGESPRIZES_DOWNLOADED';
export const ALL_EXCHANGESPRIZES_DOWNLOADED_SUCCEEDED = 'ALL_EXCHANGESPRIZES_DOWNLOADED_SUCCEEDED';
export const ALL_EXCHANGESPRIZES_DOWNLOADED_FAILED = 'ALL_EXCHANGESPRIZES_DOWNLOADED_FAILED';

export const EXCHANGEPRIZE_CREATED = 'EXCHANGEPRIZE_CREATED';
export const EXCHANGEPRIZE_CREATED_SUCCEEDED = 'EXCHANGEPRIZE_CREATED_SUCCEEDED';
export const EXCHANGEPRIZE_CREATED_FAILED = 'EXCHANGEPRIZE_CREATED_FAILED';

export const EXCHANGEPRIZE_DELETED = 'EXCHANGEPRIZE_DELETED';
export const EXCHANGEPRIZE_DELETED_SUCCEEDED = 'EXCHANGEPRIZE_DELETED_SUCCEEDED';
export const EXCHANGEPRIZE_DELETED_FAILED = 'EXCHANGEPRIZE_DELETED_FAILED';

export const EXCHANGEPRIZE_UPDATED = 'EXCHANGEPRIZE_UPDATED';
export const EXCHANGEPRIZE_UPDATED_SUCCEEDED = 'EXCHANGEPRIZE_UPDATED_SUCCEEDED';
export const EXCHANGEPRIZE_UPDATED_FAILED = 'EXCHANGEPRIZE_UPDATED_FAILED';
