import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import * as selectors from '../../reducers';
import * as actions from '../../actions/new';
import * as chainsActions from '../../actions/chain';
import * as modalActions from '../../actions/modal';


class News extends Component {
  componentDidMount(){
    const {
      fetchNews,
      fetchAllChains,
    } = this.props;
    fetchNews();
    fetchAllChains();
 
  }


  render() {
    const {
      createNew,
      deleteNew,
      updateNew,
      showNewsModal,
      news,
      chains,
      horario = [
        {
          id: '09:00',
          name: '9 AM'
        },
        {
          id: '15:00',
          name: '3 PM'
        },
      ]
    } = this.props;
    
    const header = [
      {
        text: 'Imagen',
        name: 'path_to_picture',
        type: 'file',
      },
      {
        text: 'Nombre',
        name: 'name',
        type: 'string',
      },
      {
        text: 'Link',
        name: 'link_to_news',
        type: 'string',
        link: true,
      },
      {
        text: 'Cadena',
        name: 'franchise',
        type: 'select',
        options: chains.map((chain) => {
          return ({
            value:  chain.id,
            label: chain.name,
          })
        }),
      },
      {
        text: 'Fecha',
        name: 'date',
        type: 'date',
      },
      {
        text: 'Horario',
        name: 'time',
        type: 'select',
        options: horario.map((horario) => {
          return ({
            value:  horario.id,
            label: horario.name,
          })
        }),
      },
    ];
    return (
      <Table
        title="Noticias"
        header={header}
        data={news}
        createAction={createNew.bind(this)}
        deleteAction={deleteNew.bind(this)}
        showModal={showNewsModal.bind(this)}
        updateAction={updateNew.bind(this)}
      />
  );
  }
}

export default connect(
  (state) => ({
    news: selectors.getAllNews(state),
    chains: [
      {
        id:  undefined,
        name: 'TODOS',
      },
      ...selectors.getAllChains(state),
    ],
  }),
  (dispatch) => ({
    fetchAllChains() {
      dispatch(chainsActions.fetchAllChains());
    },
    fetchNews() {
      dispatch(actions.fetchAllNews())
    },
    createNew(values) {
      dispatch(actions.createNew({
        path_to_picture: values.path_to_picture,
        name: values.name,
        link_to_news: values.link_to_news,
        franchise: values.franchise,
        date: values.date,
        time: values.time,
      }));
      console.log(values)
    },
    deleteNew(id) {
      dispatch(actions.deleteNew({
        id,
      }))
    },
    showNewsModalCore(id, data, chains) {
      const test = chains.map((chain) => {
        return ({
          value:  chain.id,
          label: chain.name,
        })
      });
      dispatch(modalActions.showUpdate({
        id: id,
        data: [
          {
            label: 'Image',
            name: 'path_to_picture',
            text: data.path_to_picture,
            type: 'file'
          },
          {
            label: 'Nombre',
            name: 'name',
            text: data.name,
            type: 'string'
          },
          {
            label: 'Link',
            name: 'link_to_news',
            text: data.link_to_news,
            type: 'string'
          },
          {
            label: 'Cadena',
            text: data.franchise,
            name: 'franchise', 
            type: 'select',
            options: test,
          },
        ],
      }))
    },
    updateNew(values){ 
      dispatch(actions.updateNew({
        id: values.id,
        path_to_picture: values.path_to_picture,
        name: values.name,
        link_to_news: values.link_to_news,
        franchise: values.franchise ? values.franchise : '',
      }))
    },
  }),
  (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    showNewsModal(id, data) {
      dispatchProps.showNewsModalCore(id, data, stateProps.chains);
    },
  })
)(News);
