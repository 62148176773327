import { combineReducers } from 'redux';
import * as types from '../types/prize';


const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_PRIZES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          prizes,
        },
      } = action;
      const prizeState = {}
      for (let i in prizes) {
        const prize = prizes[i];
        prizeState[prize.id] = {
          ...prize,
          categor: prize.category.name,
        }
      }
      return prizeState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_PRIZES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          prizes,
        },
      } = action;
      const prizeState = Object.values(prizes).map((noticia) => noticia.id);
      return prizeState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getPrizeById = (state, id) => state.byId[id] || undefined; 
export const getAllPrizes = (state) => state.order.map((id) => getPrizeById(state, id));

