import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../General/Table';
import ActivitiesFormModal from '../ActivitiesFormModal';
import ActivitiesModal from '../ActivitiesModal';
import FormDate from '../General/FormDate';
import {
  withRouter ,
} from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import * as selectors from '../../reducers';
import * as actions from '../../actions/activity';

class Activities extends Component {
  constructor(props){
    super(props)
    this.state = {
      fromDate: undefined,
      toDate: undefined,
    }
  }

  componentDidMount(){
    const {
      fetchActivityHistory,
    } = this.props;
    fetchActivityHistory({});
  }

  changeDate(date, value){
    this.setState({
      [date]: value
    })
  }

  render() {
    const {
      records,
      fetchActivityHistory,
      downloadActivityHistory
    } = this.props;
    const {
      fromDate,
      toDate,
    } = this.state;
    const header = [
      {
        text: 'Cadena',
        name: 'franchise',
        type: 'string',
      },
      {
        text: 'Sucursal',
        name: 'branch',
        type: 'string',
      },
      {
        text: 'Dependiente',
        name: 'dependiente',
        type: 'string',
      },
      {
        text: 'Monedas',
        name: 'quantity',
        type: 'string',
      },
      {
        text: 'Date',
        name: 'created_at',
        type: 'date',
      },
    ];
    return (
      <>
        <ActivitiesModal />
        <Card>
            <CardBody>
              <CardTitle>
                Historial de actividad:
              </CardTitle>
              <Row>
                <Col sm="2">
                  <FormDate 
                    placeholder="desde"
                    value={fromDate}
                    input={{
                      onChange: (value) => this.changeDate("fromDate", value)
                    }}
                  />
                </Col>
                <Col sm="2">
                  <FormDate 
                    placeholder="hasta"
                    value={toDate}
                    input={{
                      onChange: (value) => this.changeDate("toDate", value)
                    }}
                  />
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                    onClick={() => downloadActivityHistory({fromDate, toDate})}
                  >
                    Descargar
                  </Button>
                </Col>
                <Col sm="2">
                  <Button 
                    color="info"
                    type="submit"
                    onClick={() => fetchActivityHistory({fromDate, toDate})}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        <Table
          title="Actividades"
          header={header}
          data={records}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    records: selectors.getActivityHistory(state),
  }),
  (dispatch,  {match: {
    params: {
      id
    }
  }}) => ({
    fetchActivityHistory(values) {
      dispatch(actions.fetchActivityHistory({
        activityId: id,
        fromDate: values.fromDate,
        toDate: values.toDate,
      }));
    },
    downloadActivityHistory(values) {
      dispatch(actions.downloadActivityHistory({
        activityId: id,
        fromDate: values.fromDate,
        toDate: values.toDate,
      }));
    },
  })
)(withRouter(Activities));
