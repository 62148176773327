export const ALL_EXCHANGES_FETCHED = 'ALL_EXCHANGES_FETCHED';
export const ALL_EXCHANGES_FETCHED_SUCCEEDED = 'ALL_EXCHANGES_FETCHED_SUCCEEDED';
export const ALL_EXCHANGES_FETCHED_FAILED = 'ALL_EXCHANGES_FETCHED_FAILED';

export const ALL_EXCHANGES_DOWNLOADED = 'ALL_EXCHANGES_DOWNLOADED';
export const ALL_EXCHANGES_DOWNLOADED_SUCCEEDED = 'ALL_EXCHANGES_DOWNLOADED_SUCCEEDED';
export const ALL_EXCHANGES_DOWNLOADED_FAILED = 'ALL_EXCHANGES_DOWNLOADED_FAILED';

export const EXCHANGE_CREATED = 'EXCHANGE_CREATED';
export const EXCHANGE_CREATED_SUCCEEDED = 'EXCHANGE_CREATED_SUCCEEDED';
export const EXCHANGE_CREATED_FAILED = 'EXCHANGE_CREATED_FAILED';

export const EXCHANGE_DELETED = 'EXCHANGE_DELETED';
export const EXCHANGE_DELETED_SUCCEEDED = 'EXCHANGE_DELETED_SUCCEEDED';
export const EXCHANGE_DELETED_FAILED = 'EXCHANGE_DELETED_FAILED';

export const EXCHANGE_UPDATED = 'EXCHANGE_UPDATED';
export const EXCHANGE_UPDATED_SUCCEEDED = 'EXCHANGE_UPDATED_SUCCEEDED';
export const EXCHANGE_UPDATED_FAILED = 'EXCHANGE_UPDATED_FAILED';
