import * as types from '../types/chain';

export const fetchAllChains = () => ({
  type: types.ALL_CHAINS_FETCHED,
  payload: {},
});

export const fetchAllChainsConfirm = ({
  chains,
}) => ({
  type: types.ALL_CHAINS_FETCHED_SUCCEEDED,
  payload: {
    chains,
  },
});

export const fetchAllChainsDecline = ({
  message,
}) => ({
  type: types.ALL_CHAINS_FETCHED_FAILED,
  payload: {
    message,
  },
});

export const createChain = ({
  path_to_picture,
  name,
  link_to_branchs,
}) => ({
  type: types.CHAIN_CREATED,
  payload: {
    path_to_picture,
    name,
    link_to_branchs,
  },
});

export const createChainConfirm = () => ({
  type: types.CHAIN_CREATED_SUCCEEDED,
  payload: {},
});

export const createChainDecline = ({
  message,
}) => ({
  type: types.CHAIN_CREATED_FAILED,
  payload: {
    message,
  },
});

export const deleteChain = ({
  id,
}) => ({
  type: types.CHAIN_DELETED,
  payload: {
    id,
  },
});

export const deleteChainConfirm = ({
  data,
}) => ({
  type: types.CHAIN_DELETED_SUCCEEDED,
  payload: {
    data,
  }
});

export const deleteChainDecline = ({
  message,
}) => ({
  type: types.CHAIN_DELETED_FAILED,
  payload: {
    message,
  },
});

export const updateChain = ({
  id,
  path_to_picture,
  // name,
}) => ({
  type: types.CHAIN_UPDATED,
  payload: {
    id,
    path_to_picture,
    // name,
  },
});

export const updateChainConfirm = ({
  data,
}) => ({
  type: types.CHAIN_UPDATED_SUCCEEDED,
  payload: {
    data,
  },
});

export const updateChainDecline = ({
  message,
}) => ({
  type: types.CHAIN_UPDATED_FAILED,
  payload: {
    message,
  },
});
