import URL from './routes';

export const fetchAllPromotions = (
  token,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/promotion/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const createPromotion = (
  token,
  picture,
  newDpis,
  date_to_expire,
  message,
  title,
  date,
  time
) => new Promise((resolve, reject) => {
  const data = new FormData();
  console.log(date);
  picture && data.append('picture', picture);
  newDpis && data.append('dpis', newDpis);
  date_to_expire && data.append('date_to_expire', date_to_expire);
  message && data.append('message', message);
  title && data.append('title', title);
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  date && data.append('date', formattedDate);
  time && data.append('time', time)
  fetch(`${URL}/promotion/send_promotion/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
});

export const deletePromotion = (
  token,
  id,
) => new Promise((resolve, reject) => {
  fetch(`${URL}/promotion/${id}/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  }).then((resultado) => {
    if (resultado.ok) {
      resolve({
        logout: false,
      });
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 


export const updatePromotionImage = (
  token,
  id,
  path_to_picture,
) => new Promise((resolve, reject) => {
  const data = new FormData();
  path_to_picture && data.append('picture', path_to_picture);
  id && data.append('promotion_id', id);
  fetch(`${URL}/promotion/upload_picture/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 

export const updatePromotion = (
  token,
  id,
  message,
  title,
  date_to_expire
) => new Promise((resolve, reject) => {
  const data = new FormData();
  message && data.append('message', message);
  title && data.append('title', title);
  date_to_expire && data.append('date_to_expire', date_to_expire);
  fetch(`${URL}/promotion/${id}/`, {
    method: 'PATCH',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: data,
  }).then((resultado) => {
    if (resultado.ok) {
      resultado.json().then((res) => resolve({
        response: res
      }));
    } else if (resultado.status === 401) {
      resultado.json().then((res) => resolve({
        response: res,
        logout: true,
      }));
    } else {
      resultado.json().then((error) => reject(error));
    }
  }).catch((error) => reject(error));
}); 
