import { combineReducers } from 'redux';
import * as types from '../types/exchange';

const places = {
  'PHARMACY' : 'Farmacia',
  'PLACE' : 'Establecimiento',
  'NONE' : '',
}

const byId = (state={}, action) => {
  switch (action.type) {
    case types.ALL_EXCHANGES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          exchanges,
        },
      } = action;
      const exchangeState = {}
      for (let i in exchanges) {
        const createdDate = new Date(exchanges[i].exchange_at);
        const day = String(createdDate.getUTCDate()).padStart(2, '0');
        const month = String(createdDate.getUTCMonth() + 1).padStart(2, '0'); // Se suma 1 porque los meses van de 0 a 11
        const year = createdDate.getUTCFullYear();
        exchangeState[exchanges[i].id] = {
          ...exchanges[i],
          benefit: exchanges[i].benefit ? exchanges[i].benefit.name : "",
          type: exchanges[i].benefit ? places[exchanges[i].benefit.exchange_place] : "",
          user: exchanges[i].exchanged_by ? exchanges[i].exchanged_by.first_name : '',
          phone: exchanges[i].exchanged_by ? exchanges[i].exchanged_by.phone : '',
          branch: exchanges[i].exchanged_by ? exchanges[i].exchanged_by.branch.name : "",
          level: exchanges[i].benefit && exchanges[i].benefit.level_to_get ? exchanges[i].benefit.level_to_get.name : "",
          dependiente: exchanges[i].exchanged_by ? 
            `${exchanges[i].exchanged_by.first_name} ${exchanges[i].exchanged_by.second_name} ${exchanges[i].exchanged_by.first_last_name} ${exchanges[i].exchanged_by.second_last_name}`
          : 
            "",
          franchise: exchanges[i].exchanged_by ? exchanges[i].exchanged_by.branch.franchise.name : "",
          //exchange_at: new Date(exchanges[i].exchange_at).toLocaleDateString(),
          exchange_at: `${month}/${day}/${year}`,
        }
      }
      console.log(exchangeState)
      return exchangeState;
    }
    default: {
      return state;
    }
  }
}

const order = (state=[], action) => {
  switch (action.type) {
    case types.ALL_EXCHANGES_FETCHED_SUCCEEDED: {
      const {
        payload: {
          exchanges,
        },
      } = action;
      const exchangeState = Object.values(exchanges).map((noticia) => noticia.id);
      return exchangeState;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  byId,
  order
})

//selectores
export const getExchangeById = (state, id) => state.byId[id] || undefined; 
export const getAllExchanges = (state) => state.order.map((id) => getExchangeById(state, id));

