import React from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';

const FormFile = ({
  input: { onChange },
  placeholder,
  value,
}) => (
  <CustomInput
    className="form-control"
    type="file"
    label={placeholder}
    value={value}
    onChange={({ target }) => onChange(target.files[0])}
    autoFocus={false}
    formNoValidate
  />
);

FormFile.propTypes = {
  // cambiar a un objeto
  input: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default FormFile;
