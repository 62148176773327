import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as selector from '../../reducers';
import AppLayout from '../Layout';
import LoginLayout from '../Login';

class App extends Component {
  render(){
    const {
      userAuthenticated,
    } = this.props;
    return (
      <Switch>
      {
        userAuthenticated ? (
          <Route path="/" component={() => <AppLayout />} />
        ) : (
          <Route path="/" component={() => <LoginLayout />} />
        )
      }
      </Switch>
    );
  }
}

App.propTypes = {
  userAuthenticated: PropTypes.bool.isRequired,
};

export default connect(
  (state) => ({
    userAuthenticated: selector.isUserAuthenticated(state),
  }),
  undefined,
)(App);
