import {
  put,
  takeLatest,
  call,
  select,
} from 'redux-saga/effects';
import { getUserToken } from '../reducers';
import * as types from '../types/observer';
import * as api from '../apis/observer';
import * as actions from '../actions/observer';
import * as loginActions from '../actions/login';
import * as modalActions from '../actions/modal';

function* observersFetcher(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      fromDate,
      toDate,
    },
  } = action;
  try {
    const { response, logout } = yield call(
      api.fetchAllObservers,
      token,
      fromDate,
      toDate,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.fetchAllObserversConfirm({
        observers: response,
      }));
    }
  } catch (error) {
    yield put(actions.fetchAllObserversDecline({
      message: error.message,
    }));
  }
}

function* observersDownloader(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      fromDate,
      toDate,
    },
  } = action;
  try {
    const { logout } = yield call(
      api.downloadAllObservers,
      token,
      fromDate,
      toDate,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.downloadAllObserversConfirm({
      }));
    }
  } catch (error) {
    yield put(actions.downloadAllObserversDecline({
      message: error.message,
    }));
  }
}

function* observersCreator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      name,
      link_to_observers,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.createObserver,
      token,
      name,
      link_to_observers,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.createObserverConfirm());
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos creados existosamente!'
      }));
      yield put(actions.fetchAllObservers());
    }
  } catch (error) {
    yield put(actions.createObserverDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo crear los datos',
    }))
  }
}

function* observersEliminator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.deleteObserver,
      token,
      id
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteObserverConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos eliminados existosamente!'
      }));
      yield put(actions.fetchAllObservers());
    }
  } catch (error) {
    yield put(actions.deleteObserverDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo eliminar los datos',
    }))
  }
}

function* observersUpdator(action) {
  const token = yield select(getUserToken);
  const {
    payload: {
      id,
      name,
      link_to_observers,
    }
  } = action;
  try {
    const { /*response,*/ logout } = yield call(
      api.updateObserver,
      token,
      id,
      name,
      link_to_observers,
    );
    if (logout) {
      yield put(loginActions.userLogout());
    } else {
      yield put(actions.deleteObserverConfirm({}));
      yield put(modalActions.showSuccess({
        title: 'Mensaje',
        message: 'Datos actualizados existosamente!'
      }));
      yield put(actions.fetchAllObservers());
    }
  } catch (error) {
    yield put(actions.deleteObserverDecline({
      message: error.message,
    }));
    yield put(modalActions.showError({
      title: 'Hubo un error',
      message: 'No se pudo actualizar los datos',
    }))
  }
}

export default function* ObserverSaga() {
  yield takeLatest(
    types.ALL_OBSERVERS_FETCHED,
    observersFetcher,
  );
  yield takeLatest(
    types.ALL_OBSERVERS_DOWNLOADED,
    observersDownloader,
  );
  yield takeLatest(
    types.OBSERVER_CREATED,
    observersCreator,
  );
  yield takeLatest(
    types.OBSERVER_DELETED,
    observersEliminator,
  );
  yield takeLatest(
    types.OBSERVER_UPDATED,
    observersUpdator,
  );
}
